import React, { forwardRef, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { addCommas, numberOnly } from "../bin/common.js";
import ReactSelect from "react-select";
import ButtonComponent from "../compontents/Button.js";
import { InputBox } from "../library/styled-components.js";
import DayCalendarPicker from "../compontents/DayCalendarPicker.js";
import SelectedFilterBox from "../compontents/SelectedFilterBox.js";
import { useEffect } from "react";
const fieldsOrder = [
  "const_nm",
  "site_nm",
  "begin_day",
  "end_day",
  "site_code",
  "work_field",
  "contract_amt",
  "orderer_nm",
  "contractor_nm",
  "manager_nm",
  "distance",
];

const NewSiteForm = forwardRef((props, ref) => {
  const {
    workFieldOptions,
    initPopupData = () => {},
    teamsOptions,
    addr,
    subAddr,
    managerData,
  } = props.data;
  const {
    setOpendMap,
    setOpenMapMode,
    setCancelSelectSiteManager,
    setOpendSelectSiteManager,
  } = props.state;
  const { handleNewSite = () => {} } = props;
  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      const_nm: "",
      site_nm: "",
      begin_day: "",
      end_day: "",
      work_field: "",
      contract_amt: "",
      orderer_nm: "",
      contractor_nm: "",
      retirement_num: "",
      employment_num: "",
      health_num: "",
      tax_user_nm: "",
      tax_email_addr: "",
      tax_phone_num: "",
      department: "",
      manager_nm: "",
      distance: "",
    },
  });

  useEffect(() => {
    setValue("manager_nm", managerData?.userData?.user_nm);
  }, [managerData]);

  const onSubmit = (e) => {
    if (!addr) {
      alert("현장주소를 등록해 주세요.");
      return;
    }

    // 금액 부분 , 삭제 후 숫자로 변경
    e.contract_amt = parseInt(e.contract_amt.replaceAll(",", ""));
    e.distance = parseInt(e.distance.replaceAll(",", ""));
    e.addr2 = subAddr;
    e.addr1 = addr;
    e.department = e.department === "" ? 0 : Number(e.department.value);
    e.work_field = !e.work_field ? "M0003D0001" : e.work_field?.value;

    handleNewSite(e);
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);

    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));

      if (errors[firstError]?.type === "noSpaces") {
        setValue(firstError, "");
      }
      setFocus(firstError);
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  return (
    <FieldGroup
      className="new-site-form"
      ref={ref}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox fulled>
        <h6>
          공사명 <span>*</span>
        </h6>
        <input
          name="const_nm"
          type="text"
          {...register("const_nm", {
            validate: {
              register: (value) => value !== "" || "공사명을 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "공사명을 등록해 주세요.",
            },
          })}
          maxLength={50}
        />
      </InputBox>
      <InputBox fulled>
        <h6>
          현장명 <span>*</span>
        </h6>
        <input
          name="site_nm"
          type="text"
          {...register("site_nm", {
            validate: {
              register: (value) => value !== "" || "현장명을 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "현장명을 등록해 주세요.",
            },
          })}
          maxLength={50}
        />
      </InputBox>
      <InputBox fulled>
        <h6>
          공사기간 (시작일) <span>*</span>
        </h6>
        <DayCalendarPicker
          {...register("begin_day", {
            validate: {
              register: (value) =>
                value !== "" || "공사기간 (시작일)을 등록해 주세요.",
            },
          })}
          isValue={getValues("begin_day") || ""}
          style={{ height: "37px", width: "100%" }}
          change={(newValue) => setValue("begin_day", newValue)}
          name="begin_day"
          type={"date"}
          id="begin_day"
        />
      </InputBox>

      <InputBox fulled>
        <h6>
          공사기간 (종료일) <span>*</span>
        </h6>
        <DayCalendarPicker
          {...register("end_day", {
            validate: {
              register: (value) =>
                value !== "" || "공사기간 (종료일)을 등록해 주세요.",
            },
          })}
          isValue={getValues("end_day") || ""}
          change={(newValue) => setValue("end_day", newValue)}
          style={{ height: "37px", width: "100%" }}
          name="end_day"
          type={"date"}
          id="end_day"
        />
      </InputBox>

      <InputBox fulled>
        <h6>
          현장코드 <span>*</span>
        </h6>
        <input
          name="site_code"
          type="text"
          {...register("site_code", {
            validate: {
              register: (value) => value !== "" || "현장코드를 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "현장코드를 등록해 주세요.",
            },
          })}
          maxLength={25}
        />
      </InputBox>
      <InputBox fulled>
        <h6>
          공종 <span>*</span>
        </h6>
        <Controller
          name="work_field"
          control={control}
          render={({ field }) => (
            <SelectedFilterBox
              {...field}
              placeholder={""}
              style={{ width: "100%", height: "37px", font: "16px" }}
              value={getValues("work_field") || workFieldOptions[0]}
              onChange={(e) => {
                setValue("work_field", {
                  value: e.value,
                  label: e.label,
                });
              }}
              options={workFieldOptions}
            ></SelectedFilterBox>
          )}
        />
      </InputBox>
      <InputBox fulled>
        <h6>
          계약금액(부가세포함) <span>*</span>
        </h6>
        <input
          name="contract_amt"
          type="text"
          {...register("contract_amt", {
            validate: {
              register: (value) =>
                value !== "" || "계약금액(부가세포함)을 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "계약금액(부가세포함)을 등록해 주세요.",
            },
          })}
          onChange={(e) => {
            numberOnly(e);
            e.target.value = addCommas(parseInt(e.target.value));
          }}
        />
      </InputBox>

      <InputBox fulled>
        <h6>
          발주처(원도급공사) <span>*</span>
        </h6>
        <input
          name="orderer_nm"
          type="text"
          {...register("orderer_nm", {
            validate: {
              register: (value) =>
                value !== "" || "발주처(원도급공사)를 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "발주처(원도급공사)를 등록해 주세요.",
            },
          })}
          maxLength={50}
        />
      </InputBox>

      <InputBox fulled>
        <h6>
          원도급사(하도급공사) <span>*</span>
        </h6>
        <input
          name="contractor_nm"
          type="text"
          {...register("contractor_nm", {
            validate: {
              register: (value) =>
                value !== "" || "원도급사(하도급공사)를 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "원도급사(하도급공사)를 등록해 주세요.",
            },
          })}
          maxLength={50}
        />
      </InputBox>
      <InputBox fulled>
        <h6>퇴직공제 가입번호</h6>
        <input
          name="retirement_num"
          type="text"
          {...register("retirement_num")}
          maxLength={50}
        />
      </InputBox>

      <InputBox fulled>
        <h6>고용/산재 관리번호</h6>
        <input
          name="employment_num"
          type="text"
          {...register("employment_num")}
          maxLength={50}
        />
      </InputBox>
      <InputBox fulled>
        <h6>국민/건강 관리번호</h6>
        <input
          name="health_num"
          type="text"
          {...register("health_num")}
          maxLength={50}
        />
      </InputBox>
      <InputBox fulled>
        <h6>세금계산서 담당자</h6>
        <input
          name="tax_user_nm"
          type="text"
          {...register("tax_user_nm")}
          maxLength={50}
        />
      </InputBox>

      <InputBox fulled>
        <h6>세금계산서 이메일</h6>
        <input
          name="tax_email_addr"
          type="text"
          {...register("tax_email_addr")}
          maxLength={50}
        />
      </InputBox>

      <InputBox fulled>
        <h6>세금계산서 담당자 전화번호</h6>
        <input
          name="tax_phone_num"
          type="text"
          {...register("tax_phone_num")}
          maxLength={11}
        />
      </InputBox>
      <InputBox fulled>
        <h6>관리 부서 </h6>
        <Controller
          name="department"
          control={control}
          render={({ field }) => (
            <SelectedFilterBox
              {...field}
              style={{ width: "100%", height: "37px", font: "16px" }}
              //   value={teamsOptions[0]}
              placeholder={"관리 부서를 선택해 주세요"}
              onChange={(e) => {
                setValue("department", {
                  value: e.value,
                  label: e.label,
                });
              }}
              value={getValues("department") || ""}
              options={teamsOptions}
            ></SelectedFilterBox>
          )}
        />
      </InputBox>

      <InputBox fulled style={{ gridColumn: "1/3" }}>
        <h6>
          현장소장 <span>*</span>
        </h6>

        <ButtonComponent
          type="button"
          onClick={() => {
            initPopupData();
            setCancelSelectSiteManager(false);
            setOpendSelectSiteManager(true);
          }}
          style={{
            backgroundColor: "#708090",
            color: "aliceblue",
            width: "fit-content",
            lineHeight: "32px",
            boxSize: "border-box",
            padding: "0 9px",
            marginTop: "7px",
          }}
        >
          검색
        </ButtonComponent>

        <input
          name="manager_nm"
          type="text"
          placeholder="검색을 이용해 주세요"
          readOnly={true}
          value={getValues("manager_nm") || ""}
          {...register("manager_nm", {
            validate: {
              register: (value) => value !== "" || "현장 소장을 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "현장 소장을 등록해 주세요.",
            },
          })}
        />
      </InputBox>

      <InputBox fulled style={{ gridColumn: "1/3" }}>
        <h6>
          현장주소 <span>*</span> (2개 이상의 현장주소 등록은 현장정보 수정에서
          가능합니다.)
        </h6>
        <ButtonComponent
          type="button"
          onClick={() => {
            setOpendMap(true);
            setOpenMapMode({ modeName: "create" });
          }}
          style={{
            backgroundColor: "#708090",
            color: "aliceblue",
            marginTop: "7px",
            width: "fit-content",
            lineHeight: "32px",
            boxSize: "border-box",
            padding: "0 9px",
          }}
        >
          주소등록
        </ButtonComponent>
        {/* <Button
            style={{
              backgroundColor: "#708090",
              color: "aliceblue",
              marginTop: "7px",
            }}
            onClick={() => {
              setOpendMap(true);
              setOpenMapMode({ modeName: "create" });
            }}
          >
            주소등록
          </Button> */}

        <ul
          style={
            !addr
              ? {
                  margin: "5px 0 8px",
                  borderRadius: "3px",
                  padding: "8px 5px",
                }
              : {
                  margin: "5px 0 8px",
                  background: "#edf2f7",
                  borderRadius: "3px",
                  padding: "8px 5px",
                }
          }
        >
          {addr && (
            <li style={{ padding: "3px 0" }}>
              {`${addr} ${subAddr === null ? "" : subAddr}`}
            </li>
          )}
        </ul>
      </InputBox>
      <InputBox fulled style={{ gridColumn: "1/3" }}>
        <h6>
          가상펜스<span style={{ marginLeft: "3px" }}>*</span> (현장
          사무실에서부터 거리)
        </h6>

        <input
          name="distance"
          type="text"
          {...register("distance", {
            validate: {
              register: (value) => {
                if (!value) {
                  return "가상펜스를 입력해주세요";
                }

                if (
                  Number(value.replaceAll(",", "")) < 50 ||
                  Number(value.replaceAll(",", "")) > 1000
                ) {
                  return "가상펜스는 50 ~ 1,000 미터 이내로 등록해 주세요.";
                }
              },
              noSpaces: (value) =>
                value?.trim() !== "" || "가상펜스를 입력해주세요",
            },
          })}
          onChange={(e) => {
            numberOnly(e);
            e.target.value = addCommas(parseInt(e.target.value));
          }}
        />
        <h6>* 가상펜스는 50 ~ 1,000 미터 이내로 등록해 주세요.</h6>
      </InputBox>
    </FieldGroup>
  );
});

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 12px;
  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;
const Field = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  span {
    color: #ff3838;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 37px;
    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {
    }
  }

  width: ${({ fulled }) => (fulled ? `100%` : "48%")};

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

const FieldGroup = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 64px;

  > div {
    row-gap: 6px;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-size: 16px !important;
    height: 37px;
  }

  label {
    margin-left: 0;
    align-items: flex-start;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      color: #171923;
      font-weight: 500;
    }
  }

  h5 {
    //margin-bottom: 0 !important;
    //margin-top: 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
  }
`;
export default NewSiteForm;
