import styled from "@emotion/styled";
import { Header } from "../compontents/Header";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import Pagination from "react-js-pagination";
import { ModalButton } from "../compontents/ModalButton";
import Modal from "../compontents/Modal";
import SideModal from "../compontents/SideModal";
import { PagingFooter } from "../compontents/PagingFooter";
import axiosInstance from "../library/axios-index";
import dayjs from "dayjs";
import { addCommas, BlockScrolling, checkRole } from "../bin/common";
import { Tooltip } from "react-tooltip";
import KakaoMap from "../compontents/KakaoMap";
import AxiosNavigate from "../library/axios-navigate";
import { RoleType } from "../bin/enum";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import CustomTimePicker from "../compontents/TimePicker";
import PopUpModal from "../compontents/PopUpModal";
import PlanSelectModal from "../compontents/PlanSelectModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  siteAddressCreate,
  siteAddressDelete,
  siteAddressList,
  siteAddressUpdate,
} from "../library/axios-query";
import SelectedFilter from "../compontents/SelectedFilter";
import Checkbox from "react-custom-checkbox";
import SearchBox from "../compontents/Search";
import PagesList from "../compontents/PagesList";
import EmptyContainer from "../compontents/EmptyContainer";
import Loading from "../compontents/Loading";
import SelectedFilterBox from "../compontents/SelectedFilterBox";
import CustomChecked from "../compontents/CustomChecked";
import NaverMap from "../compontents/NaverMap";
import NaverMapOverlay from "../compontents/NaverMapOverlay";
import { FormProvider, useForm } from "react-hook-form";
import NewSiteForm from "./new-site-form.js";
import ModifySiteForm from "./modify-site-form.js";

const selectStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #E2E8F0",
    },
  }),
};

const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];
const workWeekOptions = [
  { value: 1, label: "1일" },
  { value: 2, label: "2일" },
  { value: 3, label: "3일" },
  { value: 4, label: "4일" },
  { value: 5, label: "5일" },
  { value: 6, label: "6일" },
  { value: 7, label: "7일" },
];
const filterOptions = [
  { value: "A", label: "전체 현장" },
  { value: "N", label: "진행중" },
  { value: "Y", label: "종료" },
];

const dayWeekKo = {
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  sunday: "일",
};
const dayWeekEn = {
  월: "monday",
  화: "tuesday",
  수: "wednesday",
  목: "thursday",
  금: "friday",
  토: "saturday",
  일: "sunday",
};

function Site() {
  const location = useLocation();
  const methods = useForm();
  const [cookies] = useCookies(["role"]);
  const formRef = useRef();
  const [loading, setLoading] = useState(true);

  const [limit, setLimit] = useState(options[0].value);
  const [page, setPage] = useState(5);
  const [searchTxt, setSearchTxt] = useState("");
  const [filter, setFilter] = useState("N");
  const [searchValue, setSearchValue] = useState("");
  const [sites, setSites] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [detailSite, setDetailSite] = useState({});
  const [workFieldOptions, setWorkFieldOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [workWeek, setWorkWeek] = useState({
    월: false,
    화: false,
    수: false,
    목: false,
    금: false,
    토: false,
    일: false,
  });

  const [workWeekSelect, setWorkWeekSelect] = useState(0);
  const [addr, setAddr] = useState("");
  const [subAddr, setSubAddr] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [logitude, setLogitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const [opendDetailSite, setOpendDetailSite] = useState(false);
  const [opendNewSite, setOpendNewSite] = useState(false);
  const [opendModifySite, setOpendModifySite] = useState(false);
  const [opendLocation, setOpendLocation] = useState(false);

  const [opendWorkTimeSet, setOpendWorkTimeSet] = useState(false);
  const [modifyTimeData, setModifyTimeData] = useState({
    am_begin: "00:00:00",
    am_end: "00:00:00",
    pm_begin: "12:00:00",
    pm_end: "12:00:00",
    satday_yn: "N",
    satday_begin: "00:00:00",
    satday_end: "00:00:00",
    satday_pm_begin: "12:00:00",
    satday_pm_end: "12:00:00",
    weekend_yn: "N",
    weekend_begin: "00:00:00",
    weekend_end: "00:00:00",
    weekend_pm_begin: "12:00:00",
    weekend_pm_end: "12:00:00",
    overtime_yn: "N",
    overtime_begin: "12:00:00",
    overtime_end: "12:00:00",
    overnight_yn: "N",
  });

  const [formFields, setFormFields] = useState([]);
  const [addFormField, setAddFormField] = useState(true);

  const [endYtabColor, setEndYtabColor] = useState("#fff");
  const [endNtabColor, setEndNtabColor] = useState("#EDF2F7");
  const [endYn, setEndYn] = useState("Y");
  const [openPlanSelect, setOpenPlanSelect] = useState(false);

  const [opendMap, setOpendMap] = useState(false);
  const [openMapMode, setOpenMapMode] = useState({ modeName: "", items: "" });

  const [siteManager, setSiteManager] = useState([]);
  const [opendSelectSiteManager, setOpendSelectSiteManager] = useState(false);
  const [opendSiteManagerPopup, setOpendSiteManagerPopup] = useState(false);
  const [cancelSelectSiteManager, setCancelSelectSiteManager] = useState(false);
  const [popUpData, setPopupData] = useState({
    response: false,
    userData: null,
    content: null,
  });

  const [managerData, setMangerData] = useState({});
  const [buttonType, setButtonType] = useState(null);

  const modalClose = useRef(false);

  BlockScrolling([
    opendModifySite,
    opendDetailSite,
    opendWorkTimeSet,
    opendSiteManagerPopup,
    openPlanSelect,
    opendSelectSiteManager,
    opendMap,
    opendNewSite,
    opendLocation,
  ]);

  const handleSiteInfo = useCallback(
    (site_seq) => {
      setDetailSite(sites.find((e) => e.site_seq === site_seq));
    },
    [sites]
  );

  const queryClient = useQueryClient();

  // 현장 주소 수정하기
  const { mutate: addressUpdate, data: addressUpdateItem } = useMutation({
    mutationKey: ["siteAddressUpdate"],
    mutationFn: siteAddressUpdate,
    onSuccess: (data) => {
      getAgainSiteAddress();
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 주소 리스트
  const { mutate: getSiteAddress, data: getSiteAddressList } = useMutation({
    mutationKey: ["siteAddressList"],
    mutationFn: siteAddressList,
    onSuccess: (data) => {},
    onError: (error) => {
      throw error;
    },
  });

  // 현장 주소 생성
  const { mutate: addressCreate, data: addressCreateList } = useMutation({
    mutationKey: ["siteAddressCreate"],
    mutationFn: siteAddressCreate,
    onSuccess: (data) => {
      getAgainSiteAddress();
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 주소 삭제
  const { mutate: addressDelete, data: addressDeleteList } = useMutation({
    mutationKey: ["siteAddressDelete"],
    mutationFn: siteAddressDelete,
    onSuccess: (data) => {
      getAgainSiteAddress();
    },
    onError: (error) => {
      throw error;
    },
  });

  const getAgainSiteAddress = useCallback(() => {
    getSiteAddress(detailSite?.site_seq);
  }, [detailSite?.site_seq]);

  const handleWorkTimeSet = (site_seq) => {
    setOpendWorkTimeSet(true);
    const site = sites.find((e) => e.site_seq === site_seq);
    let newModifyTimeData = {
      ...modifyTimeData,
    };

    for (let key in site) {
      if (site.hasOwnProperty(key) && site[key] !== null) {
        newModifyTimeData[key] = site[key];
      }
    }

    newModifyTimeData.rest_day.split(",").map((item) =>
      setWorkWeek((prevState) => ({
        ...prevState,
        [item]: true,
      }))
    );
    setWorkWeekSelect(newModifyTimeData.work_day_cnt);

    setModifyTimeData({
      ...newModifyTimeData,
      time_range:
        site.time_range === null
          ? "0"
          : parseInt(site.time_range.split(":")[1], 10),
    });
  };

  const getValue = (arr, name) => arr.find((e) => e.name === name).value;

  const changeTime = (name, value) => {
    setModifyTimeData({
      ...modifyTimeData,
      [name]: value,
    });
  };

  const handleAddrAndZoneCode = (fullAddr, subAddr, zoneCode = "") => {
    setAddr(fullAddr);
    setZoneCode(zoneCode);
    setSubAddr(subAddr);
  };

  const handleLogitudeAndLatitude = (logitude, latitude) => {
    setLogitude(logitude);
    setLatitude(latitude);
  };

  const handleAddrInfo = (
    addr,
    subAddr,
    zoneCode,
    logitude,
    latitude,
    mode
  ) => {
    // return;
    if (mode.modeName === "modify") {
      const modifyItems = {
        addr_seq: mode?.items?.addr_seq,
        default_yn: mode.items.default_yn,
        site_seq: mode.items.site_seq,
        addr1: addr,
        addr2: subAddr == "" ? null : subAddr,
        latitude: latitude,
        logitude: logitude,
        postal_cd: zoneCode,
      };

      addressUpdate(modifyItems);
      // 수정하기 페이지 > 수정하기
    } else if (mode.modeName === "add") {
      // 수정하기 페이지 > 주소등록하기
      const addItems = {
        site_seq: detailSite?.site_seq,
        postal_cd: zoneCode,
        addr1: addr,
        addr2: subAddr == "" ? null : subAddr,
        latitude: latitude,
        logitude: logitude,
      };
      addressCreate(addItems);
    } else {
      // 현장 추가하기 페이지
      handleAddrAndZoneCode(addr, subAddr, zoneCode);
      handleLogitudeAndLatitude(logitude, latitude);
    }
  };

  const initPopupData = useCallback(() => {
    setPopupData({ response: false, userData: null, content: null });
    setCancelSelectSiteManager(false);
  }, [popUpData]);

  const handleFindManager = useCallback(
    async (e) => {
      const inputs = Array.from(document.querySelectorAll("input"));
      const phoneNum =
        getValue(inputs, "site_manager_phone_num1") +
        "-" +
        getValue(inputs, "site_manager_phone_num2") +
        "-" +
        getValue(inputs, "site_manager_phone_num3");

      const managerName = getValue(inputs, "site_manager_nm");
      if (buttonType == "modify") {
        if (
          detailSite?.phone_num == phoneNum.replaceAll("-", "") ||
          detailSite?.manager_phone_num_temp == phoneNum.replaceAll("-", "")
        ) {
          alert("이미 등록된 현장소장 연락처 입니다.");
          return;
        }
      }

      if (managerName == null || phoneNum.length < 13) {
        alert("현장소장 이름과 핸드폰 번호를 확인해주세요");
        return;
      }

      const {
        data: { result },
      } = await axiosInstance.post("/company/get/manager-user-info", {
        user_nm: managerName,
        phone_num: phoneNum.replaceAll("-", ""),
      });

      let userData = null;
      let content = null;
      let response;

      if (Object.keys(result).length != 0) {
        response = true;
        userData = {
          user_seq: result.user_seq,
          user_nm: result.user_nm,
          phone_num: result.phone_num,
          photo: result.photo,
        };
        content = "현장소장을 등록합니다";
      } else {
        response = false;
        userData = null;
        content =
          "대상자가 일사에 가입되어 있지 않습니다.\n현장소장은 일사 가입자만 등록이 가능합니다.";
        // "대상자가 일사에 가입되어 있지 않습니다.\n\n먼저 등록하시겠습니까?\n\n(아래 정보로 일사 가입 요청 문자가 전송됩니다)";
      }

      setPopupData({
        response: response,
        userData: userData,
        content: content,
      });

      setOpendSiteManagerPopup(true);
    },
    [popUpData, detailSite, buttonType]
  );

  const loadSearchSiteManager = useCallback(
    async (value) => {
      const token = localStorage.getItem("admin_token");
      // filter 4 = 입사자 및 승인 대기자 목록
      const {
        data: {
          result: { laborEmployees, countOfLaborEmploy },
        },
      } = await axiosInstance.post(`/admin/laborEmployList`, {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: value,
        page: 1,
        limit: 100,
        filter: "4",
      });
      const currentSiteManger = laborEmployees.filter(
        (e) => e.site_seq === detailSite?.site_seq
      );
      setSiteManager(currentSiteManger);
    },
    [siteManager, detailSite]
  );

  useEffect(() => {
    if (opendSelectSiteManager) {
      (async () => {
        await loadSearchSiteManager("");
      })();
    }
  }, [opendSelectSiteManager]);

  // 자식 컨테이너 함수 호출용(카카오 맵 에서 사용)
  const childRef = useRef({});

  /* 수정하기->설계 변경 필드 추가*/
  const handleAddFields = () => {
    // 필드 추가 시 설계 변경 이력이 하나도 없다면, 초기값은 현재 계약금액, 공사기간
    // 설계 변경 이력이 있다면, 초기값은 마지막 설계 변경 필드의 계약금액, 공사 기간
    if (addFormField) {
      let lastContractAmtField = "";
      let lastBeginDayField = "";
      let lastEndDayField = "";

      if (formFields.length > 1) {
        lastContractAmtField = formFields[formFields.length - 1].contractAmt;
        lastBeginDayField = formFields[formFields.length - 1].beginDay;
        lastEndDayField = formFields[formFields.length - 1].endDay;
      } else {
        const formData = Array.from(
          document.querySelectorAll(".modify-site-form input")
        ).reduce((acc, cur, i) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {});

        lastContractAmtField = formData.contract_amt;
        lastBeginDayField = formData.begin_day;
        lastEndDayField = formData.end_day;
      }

      const values = [
        ...formFields,
        {
          contractAmt: lastContractAmtField,
          beginDay: lastBeginDayField,
          endDay: lastEndDayField,
        },
      ];
      setFormFields(values);
    }
    setAddFormField(false);
  };
  /* 기존 설계 변경 출력 전 초기화*/
  const handleInitFields = (changeHistory) => {
    let values = [];
    if (changeHistory.length !== 0) {
      changeHistory?.map((item) => {
        values = [
          ...values,
          {
            contractAmt: addCommas(item.contract_amt),
            beginDay: item.begin_day,
            endDay: item.end_day,
          },
        ];
      });
    }
    setFormFields(values);
  };

  const handleInputChange = (index, e) => {
    const values = [...formFields];
    if (e.target.name === "contractAmt") {
      numberOnly(e);
      e.target.value = addCommas(parseInt(e.target.value));
      if (e.target.value.length >= e.target.maxLength) {
        const nextInput = e.target.nextElementSibling;
        if (nextInput) {
          nextInput.focus();
        }
        values[index].contractAmt = e.target.value;
      }
    } else if (e.target.name === "beginDay") {
      values[index].beginDay = e.target.value;
    } else {
      // 종료일을 시작일 보다 이른 날짜로 잡으면 시작일과 동일하게 입력됨. 시작일을 선택하지 않으면 입력 불가
      if (values[index].beginDay !== "") {
        if (e.target.value <= values[index].beginDay) {
          values[index].endDay = "";
        } else {
          values[index].endDay = e.target.value;
        }
      }
    }
    setFormFields(values);
  };

  const handleInputDate = (e) => {
    // 공사기간(종료일)을 공사기간(시작일)보다 빠른 날짜로 설정하지 못함.
    let beginDay = "";
    if (e.target.id === "end_day_new") {
      beginDay = document.getElementById("begin_day_new");
    } else {
      beginDay = document.getElementById("begin_day_modify");
    }
    if (beginDay.value) {
      if (e.target.value < beginDay.value) {
        e.target.value = "";
      }
    } else {
      e.target.value = "";
    }
  };

  /* 설계 변경 필드 추가 시 추가된 필드에만 입력 가능하게 설정 */
  useEffect(() => {
    if (!addFormField) {
      const contractAmtFieldId = "contractAmt" + (formFields.length - 1);
      const beginDayFieldId = "beginDay" + (formFields.length - 1);
      const endDayFieldId = "endDay" + (formFields.length - 1);
      let contractAmtField = document.getElementById(contractAmtFieldId);
      let beginDayField = document.getElementById(beginDayFieldId);
      let endDayField = document.getElementById(endDayFieldId);
      contractAmtField.readOnly = false;
      //react-tooltip 사용을 위한 className 설정, 추가된 필드 오버레이 시에만 툴팁을 보여주기 위해.
      contractAmtField.className = "contractAmt" + (formFields.length - 1);
      //tooltip의 anchorSelect 가 build 시 작동하지 않아, tooltip id를 다시 설정해주는 것으로 처리
      contractAmtField.attributes.getNamedItem("data-tooltip-id").value =
        "contractAmt" + (formFields.length - 1);
      beginDayField.readOnly = false;
      endDayField.readOnly = false;
    }
  }, [addFormField]);

  //수정 -> 진행 / 종료 탭 선택
  const handleEndYnTab = (e) => {
    if (e.target.id === "endN") {
      setEndYtabColor("#EDF2F7");
      setEndNtabColor("#fff");
      setEndYn("N");
    } else {
      const answer = window.confirm(
        `현장 종료시 기존 근로자는 모두 퇴사처리되며 진행으로 복구가 되지 않습니다. \n현장 종료를 진행하시겠습니까?`
      );

      if (!answer) return;

      setEndYtabColor("#fff");
      setEndNtabColor("#EDF2F7");
      setEndYn("Y");
    }
  };

  const numberOnly = (e) => {
    if (e.target.value.match(/[^0-9]/g)) {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
  };
  const handleFilter = useCallback(
    async (value) => {
      await loadSites(searchTxt, 1, limit, value);
      setFilter(value);
    },
    [searchTxt, page, limit, filter]
  );
  const handleSearchTxt = useCallback(
    async (value) => {
      await loadSites(value, 1, limit, filter);
      setSearchTxt(value);
      setPage(1);
    },
    [searchTxt, page, limit, filter]
  );
  //handleFilter
  const handlePage = useCallback(
    async (value) => {
      await loadSites(searchTxt, value, limit, filter);

      setPage(value);
    },
    [searchTxt, page, limit, filter]
  );

  const handleLimit = useCallback(
    async (e) => {
      await loadSites(searchTxt, page, e.value, filter);

      setLimit(e.value);
      setPage(1);
    },
    [searchTxt, page, limit, filter]
  );

  const handleNewSite = useCallback(
    async (value) => {
      modalClose.current = false;

      //우편번호, 위도, 경도
      value["postal_cd"] = zoneCode;
      value["logitude"] = Number(logitude);
      value["latitude"] = Number(latitude);
      value["addr2"] = subAddr;

      // 관리 부서 값이 없으면 0으로 전달

      //현장소장 / 임시 현장소장
      if (!cancelSelectSiteManager) {
        if (popUpData.response && popUpData.userData != null) {
          value["manager_nm_temp"] = null;
          value["manager_phone_num_temp"] = null;
          value["manager_nm"] = popUpData.userData.user_nm;
          value["phone_num"] = popUpData.userData.phone_num.replaceAll("-", "");
          value["manager_user_seq"] = popUpData.userData.user_seq;
        } else {
          value["manager_nm_temp"] = popUpData?.userData?.user_nm
            ? popUpData?.userData?.user_nm
            : null;
          value["manager_phone_num_temp"] = popUpData?.userData?.phone_num
            ? popUpData?.userData?.phone_num.replaceAll("-", "")
            : null;
          value["manager_nm"] = null;
          value["phone_num"] = null;
          value["manager_user_seq"] = popUpData?.userData?.user_seq
            ? popUpData?.userData?.user_seq
            : null;
        }

        if (!popUpData.response) {
          value["user_site_seq"] = null;
        }
      }

      initPopupData();

      const token = localStorage.getItem("admin_token");

      const {
        data: {
          result: { sites, totalCount },
        },
      } = await axiosInstance.post(`/admin/siteNew`, {
        lang: "ko",
        uuid: "string",
        token,
        team_seq: value["department"],
        ...value,
      });

      setOpendNewSite(false);
      const _sites = await loadSites(searchTxt, page, limit, filter);
      setSites(_sites);
    },
    [
      searchTxt,
      page,
      limit,
      detailSite,
      zoneCode,
      addr,
      logitude,
      latitude,
      popUpData,
      cancelSelectSiteManager,
    ]
  );

  const handleModifySite = useCallback(
    async (value) => {
      //우편번호, 위도, 경도
      value["postal_cd"] = zoneCode;
      value["logitude"] = logitude;
      value["latitude"] = latitude;
      //현장소장 / 임시 현장소장
      value["end_yn"] = endYn;
      if (
        detailSite?.manager_nm_temp !== null &&
        detailSite?.manager_phone_num_temp !== null &&
        detailSite?.manager_nm === null &&
        detailSite?.phone_num === ""
      ) {
        //  소장(비회원)
        if (!popUpData.response && popUpData.userData === null) {
          value["manager_nm_temp"] = null;
          value["manager_phone_num_temp"] = null;
          value["manager_nm"] = null;
          value["phone_num"] = null;
          value["manager_user_seq"] = null;
        }
      }

      if (popUpData.response && popUpData.userData != null) {
        //   소장 변경(회원)
        value["manager_nm_temp"] = null;
        value["manager_phone_num_temp"] = null;
        value["manager_nm"] = popUpData?.userData?.user_nm;
        value["phone_num"] = popUpData?.userData?.phone_num.replaceAll("-", "");
        value["manager_user_seq"] = popUpData?.userData?.user_seq;
      } else {
        // 소장 변경(비회원)
        if (popUpData?.userData?.user_seq === null) {
          value["manager_nm"] = null;
          value["phone_num"] = null;
          value["manager_user_seq"] = null;
          value["manager_nm_temp"] = popUpData?.userData?.user_nm;
          value["manager_phone_num_temp"] =
            popUpData?.userData?.phone_num.replaceAll("-", "");
        }
      }

      const {
        data: {
          result: { sites, totalCount },
        },
      } = await axiosInstance.post(`/admin/siteModify`, {
        lang: "ko",
        uuid: "string",
        site_seq: detailSite?.site_seq,
        corp_seq: detailSite?.corp_seq,
        team_seq: value["department"],
        ...value,
      });

      const _sites = await loadSites(searchTxt, page, limit, filter);

      if (value["end_yn"] === "Y") {
        setOpendDetailSite(false);
      }
      setDetailSite((e) =>
        _sites.find((e) => e.site_seq === detailSite?.site_seq)
      );
      initPopupData();
      setOpendModifySite(false);
    },
    [
      searchTxt,
      page,
      limit,
      detailSite,
      addFormField,
      filter,
      endYn,
      zoneCode,
      logitude,
      latitude,
      popUpData,
      cancelSelectSiteManager,
    ]
  );

  const handleManagerChange = useCallback(() => {
    setMangerData(popUpData);
  });

  const loadSites = async (search_txt, page, limit, filter) => {
    setPage(page);
    setLimit(limit);

    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: { sites, totalCount },
      },
    } = await axiosInstance.post(`/admin/siteList`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt,
      page,
      limit,
      filter,
    });

    let workField = await axiosInstance.post(`/code/codeList`, {
      lang: "ko",
      uuid: "string",
      token,
      mst_cd_id: "M0003",
    });
    let teamsResp = await axiosInstance.post(`/company/listTeam`, {
      lang: "ko",
      uuid: "string",
      token,
    });

    // 오름차순으로 정렬
    teamsResp?.data?.result.sort((a, b) =>
      a.team_nm.toLowerCase() < b.team_nm.toLowerCase() ? -1 : 1
    );

    teamsResp = teamsResp?.data?.result.map((e) => {
      return { value: e.team_seq, label: e.team_nm };
    });
    setTeamsOptions(teamsResp);
    workField = workField.data.result
      .filter((e) => e.dtl_cd_nm !== "Reserved")
      .map((e) => {
        return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
      });

    setWorkFieldOptions(workField);
    setSites(sites);
    setTotalCount(totalCount);

    setTimeout(() => {
      setLoading(false);
    }, 300);
    return sites;
  };

  useEffect(() => {
    (async () => {
      await loadSites("", 1, options[0].value, filter);
    })();
  }, []);

  // 근무조건 설정 시간 변경
  const requestModifyTime = async () => {
    const weekLen = Object.values(workWeek).filter(
      (item) => item === true
    ).length;

    if (workWeekSelect === 0) {
      alert("근무일을 선택해주세요");
      return;
    }

    if (weekLen === 0) {
      alert("휴일을 선택해주세요");
      return;
    }
    if (weekLen + workWeekSelect < 7) {
      alert("근무일와 휴일 합이 7일 이어야 합니다");
      return;
    }

    const request = {
      site_seq: modifyTimeData.site_seq,
      time_range: `00:${modifyTimeData.time_range}:00`,
      am_begin: modifyTimeData.am_begin,
      am_end: modifyTimeData.am_end,
      pm_begin: modifyTimeData.pm_begin,
      pm_end: modifyTimeData.pm_end,
      satday_yn: modifyTimeData.satday_yn,
      satday_begin: modifyTimeData.satday_begin,
      satday_end: modifyTimeData.satday_end,
      satday_pm_begin: modifyTimeData.satday_pm_begin,
      satday_pm_end: modifyTimeData.satday_pm_end,
      weekend_yn: modifyTimeData.weekend_yn,
      weekend_begin: modifyTimeData.weekend_begin,
      weekend_end: modifyTimeData.weekend_end,
      weekend_pm_begin: modifyTimeData.weekend_pm_begin,
      weekend_pm_end: modifyTimeData.weekend_pm_end,
      overtime_yn: modifyTimeData.overtime_yn,
      overtime_begin: modifyTimeData.overtime_begin,
      overtime_end: modifyTimeData.overtime_end,
      workDayCount: workWeekSelect,
      overnight_yn: modifyTimeData.overnight_yn,
      restDay: Object.keys(workWeek).filter((day) => workWeek[day]),
    };

    const result = await axiosInstance.post(
      `/admin/siteModify/workTime`,
      request
    );
    setOpendWorkTimeSet(false);
    if (result.data.code === 200) {
      await loadSites(searchTxt, page, limit, filter);
    } else {
      alert("근무조건 설정에 실패하였습니다.");
    }
  };

  // 근무조건 설정 시간 유효성 검사
  function validateTime() {
    const time = modifyTimeData;

    if (
      adaptTime(time.am_begin, time.am_end) !== 240 ||
      time.am_begin === null ||
      time.am_end === null
    ) {
      alert(
        "평일 오전 근무 시간은 4시간으로 설정해 주세요.",
        adaptTime(time.am_begin, time.am_end)
      );
      return false;
    }
    if (
      adaptTime(time.pm_begin, time.pm_end) !== 240 ||
      time.pm_begin === null ||
      time.pm_end === null
    ) {
      alert("평일 오후 근무 시간은 4시간으로 설정해 주세요.");
      return false;
    }

    if (time.satday_yn === "Y") {
      const minute = adaptTime(time.satday_begin, time.satday_end);
      if (
        minute < 60 ||
        minute > 240 ||
        time.satday_begin === null ||
        time.satday_end === null
      ) {
        alert("토요일 오전 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
      const minute2 = adaptTime(time.satday_pm_begin, time.satday_pm_end);
      if (
        minute2 < 60 ||
        minute2 > 240 ||
        time.satday_pm_begin === null ||
        time.satday_pm_end === null
      ) {
        alert("토요일 오후 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
    }

    if (time.weekend_yn === "Y") {
      const minute = adaptTime(time.weekend_begin, time.weekend_end);
      if (
        minute < 60 ||
        minute > 240 ||
        time.weekend_begin === null ||
        time.weekend_end === null
      ) {
        alert("일요일/공휴일 오전 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
      const minute2 = adaptTime(time.weekend_pm_begin, time.weekend_pm_end);
      if (
        minute2 < 60 ||
        minute2 > 240 ||
        time.weekend_pm_begin === null ||
        time.weekend_pm_end === null
      ) {
        alert("일요일/공휴일 오후 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
    }

    if (time.overtime_yn === "Y") {
      const minute = adaptTime(time.overtime_begin, time.overtime_end);
      if (
        modifyTimeData?.overnight_yn === "N" &&
        (minute < 120 ||
          minute > 240 ||
          time.overtime_begin === null ||
          time.overtime_end === null)
      ) {
        alert("야간 근무 시간은 2~4시간 사이로 설정해 주세요.");
        return false;
      }

      // 야근 근무 확인
      if (
        time.pm_end > time.overtime_begin ||
        (time.weekend_pm_end > time.overtime_begin &&
          time.weekend_yn === "Y") ||
        (time.satday_pm_end > time.overtime_begin && time.satday_yn === "Y")
      ) {
        alert("야근 시작 시간은 오후 종료 시간보다 커야 합니다.");
        return false;
      }
    }

    if (time.time_range < 0 || time.time_range > 30) {
      alert("오차범위는 0~30분 사이로 설정해 주세요.");
      return false;
    }

    return true;
  }

  function adaptTime(time1, time2) {
    let t1 = new Date(`1970-01-01T${time1}`);
    let t2 = new Date(`1970-01-01T${time2}`);

    // 두 시간 사이의 차이를 계산
    let difference = Math.abs(t2 - t1);

    return Math.floor(difference / 1000 / 60);
  }

  const checkCompanyPlan = useCallback(async () => {
    try {
      await axiosInstance.post("/company/check-plan");
    } catch (e) {
      setOpenPlanSelect(true);
      return false;
    }
    return true;
  }, []);

  const findInput = (form, name) => {
    return form.find((e) => e.name === name);
  };

  const handleCheck = (checked, name) => {
    const weekLen = Object.values(workWeek).filter(
      (item) => item === true
    ).length;
    if (weekLen + workWeekSelect >= 7 && checked) {
      alert("근무일와 휴일 합이 7일 이어야 합니다");
      return 1;
    } else {
      if (!name) return;
      setWorkWeek((prevState) => ({ ...prevState, [name]: checked }));
      return 0;
    }
  };

  return (
    <AxiosNavigate>
      <div>
        <FormProvider {...methods}>
          <Header>
            <div>현장 관리</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
                justifyContent: "space-between",
              }}
            >
              <SearchBox
                placeholder="현장명 검색"
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("현장명을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }
                    handleSearchTxt(e.target.value.replaceAll(" ", ""));
                    setSearchValue(e.target.value.trim());
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>
              <SelectedFilter
                options={filterOptions}
                placeholder={"진행중"}
                value={filterOptions.find((item) => item.value === filter)}
                // iconName={"menu-02.svg"}
                style={{
                  width: "160px",
                }}
                onChange={(e) => {
                  setPage(1);
                  handleFilter(e.value);
                }}
              ></SelectedFilter>
              <Button
                style={{
                  backgroundColor: "#3279F5",
                  color: "#fff",
                }}
                onClick={async () => {
                  if (cookies.role.admin) {
                    //관리자가 일때
                    if (!(await checkCompanyPlan())) return;
                    setOpendNewSite(true);
                    handleAddrAndZoneCode("", "", "");
                    handleLogitudeAndLatitude(null, null);
                    setButtonType("new");
                    setMangerData({});
                  } else {
                    //관리자가 아닐때
                    alert(
                      "권한이 없습니다. 관리자에게 문의해 주시기 바랍니다."
                    );
                    return;
                  }
                }}
              >
                <Icon src="/assets/icons/plus.svg" />
                <span style={{ lineHeight: "normal" }}>현장추가</span>
              </Button>
            </div>
          </Header>
          {loading ? (
            <Loading></Loading>
          ) : (
            <>
              <TableContainer>
                <TableWrapper>
                  <Table>
                    <thead>
                      <tr>
                        <th>현장코드</th>
                        <th width={"700px"}>현장명</th>
                        <th>발주처/원청사</th>
                        <th>현장소장</th>
                        <th>관리부서</th>
                        <th>진행상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sites?.length === 0 ? (
                        <tr style={{ height: "500px" }}>
                          <td colSpan={6}>
                            <EmptyContainer
                              falseText={"등록된 현장이"}
                              style={{ background: "#fff" }}
                            ></EmptyContainer>
                          </td>
                        </tr>
                      ) : (
                        sites?.map((e, i) => (
                          <tr key={i}>
                            <td style={{ width: "50px" }}>{e.site_code}</td>
                            <td style={{ width: "250px" }}>
                              <div
                                style={{
                                  minWidth: "162px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {e.site_nm}
                                <div style={{ display: "flex" }}>
                                  <Button
                                    onClick={() => {
                                      handleSiteInfo(e.site_seq);
                                      handleLogitudeAndLatitude(null, null);
                                      getSiteAddress(e.site_seq);
                                      setOpendDetailSite(true);
                                    }}
                                    style={{
                                      marginLeft: "8px",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    상세보기
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      if (e.end_yn == "Y") {
                                        alert("이미 마감된 현장입니다");
                                        return;
                                      }
                                      setWorkWeekSelect(0);
                                      setWorkWeek({
                                        월: false,
                                        화: false,
                                        수: false,
                                        목: false,
                                        금: false,
                                        토: false,
                                        일: false,
                                      });
                                      handleWorkTimeSet(e.site_seq);
                                      handleLogitudeAndLatitude(null, null);
                                      handleSiteInfo(e.site_seq);
                                    }}
                                    style={{
                                      marginLeft: "8px",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    근무조건설정
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      if (!e.addr1) return;
                                      handleSiteInfo(e.site_seq);
                                      setOpendLocation(true);
                                    }}
                                    style={{
                                      marginLeft: "8px",
                                      boxSizing: "border-box",
                                      backgroundColor: !e.addr1
                                        ? "#dad9d9"
                                        : "transparent",
                                    }}
                                  >
                                    현장위치
                                  </Button>
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "50px" }}>{e.orderer_nm}</td>
                            <td style={{ width: "50px" }}>{e.manager_nm}</td>
                            <td style={{ width: "50px" }}>
                              {e?.team_seq === 0
                                ? ""
                                : teamsOptions?.find(
                                    (item) => item.value == e?.team_seq
                                  )?.label}
                            </td>
                            <td style={{ width: "50px" }}>
                              {e.end_yn == "Y" ? "종료" : "진행중"}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TableWrapper>
              </TableContainer>
              <PagingFooter>
                <Pagination
                  prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                  nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                  activePage={page}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount === 0 ? 1 : totalCount}
                  pageRangeDisplayed={5}
                  hideFirstLastPages={true}
                  onChange={handlePage}
                />
                <PagesList options={options} onChange={handleLimit}></PagesList>
              </PagingFooter>
            </>
          )}

          <SideModal
            opend={opendDetailSite}
            closeModal={() => {
              setOpendDetailSite(false);
            }}
            header={"현장 상세정보"}
            buttons={
              <>
                <ModalButton
                  onClick={() => {
                    if (
                      !checkRole(
                        location.pathname,
                        cookies.role,
                        RoleType.WRITE
                      )
                    ) {
                      return;
                    }
                    if (detailSite?.end_yn === "Y") {
                      alert("이미 종료된 현장입니다.");
                      return;
                    }
                    setOpendModifySite(true);
                    setMangerData({});
                    handleInitFields(
                      detailSite?.tb_const_site_change_histories
                    );
                    setAddFormField(true);
                    handleAddrAndZoneCode(
                      detailSite?.addr1,
                      detailSite?.postal_cd
                    );
                    handleLogitudeAndLatitude(
                      detailSite?.logitude,
                      detailSite?.latitude
                    );

                    // 수정하기 진행 / 종료 표시를 위해
                    if (detailSite?.end_yn === "N") {
                      setEndYtabColor("#EDF2F7");
                      setEndNtabColor("#fff");
                      setEndYn("N");
                    }
                    // 현장 상세 정보 => 현장 소장 변경
                    initPopupData();
                    setButtonType("modify");
                  }}
                >
                  수정하기
                </ModalButton>
              </>
            }
          >
            <div>
              {detailSite?.end_yn === "Y" ? (
                <Tabs>
                  <NoCursorTab>진행</NoCursorTab>
                  <NoCursorTab style={{ background: "#fff" }}>종료</NoCursorTab>
                </Tabs>
              ) : (
                <Tabs>
                  <NoCursorTab style={{ background: "#fff" }}>진행</NoCursorTab>
                  <NoCursorTab>종료</NoCursorTab>
                </Tabs>
              )}
            </div>
            <Content>
              <figure>
                <h6>공사명</h6>
                <h5>{detailSite?.const_nm}</h5>
              </figure>
              <figure>
                <h6>공사기간</h6>
                <h5>
                  {detailSite?.begin_day == "1899-11-30"
                    ? "0000년 00월 00일"
                    : dayjs(detailSite?.begin_day).format(
                        "YYYY년 M월 D일"
                      )}{" "}
                  ~{" "}
                  {detailSite?.end_day == "1899-11-30"
                    ? "0000년 00월 00일"
                    : dayjs(detailSite?.end_day).format("YYYY년 M월 D일")}
                </h5>
              </figure>
              <figure>
                <h6>현장명</h6>
                <h5>{detailSite?.site_nm}</h5>
              </figure>
              <figure>
                <h6>현장코드</h6>
                <h5>{detailSite?.site_code}</h5>
              </figure>
              <figure>
                <h6>공종</h6>
                <h5>
                  {detailSite?.work_field === null
                    ? ""
                    : workFieldOptions.find(
                        (e) => e.value === detailSite?.work_field
                      )?.label}
                </h5>
              </figure>
              <figure>
                <h6>계약금액(부가세포함)</h6>
                <h5>{addCommas(detailSite?.contract_amt)}원</h5>
              </figure>
              <hr />
              {detailSite?.tb_const_site_change_histories?.map(
                (item, index) => (
                  <div key={index}>
                    <figure>
                      <h6>설계변경 ({index + 1}차)</h6>
                      <h5></h5>
                    </figure>
                    <figure>
                      <h6>계약금액(부가세포함)</h6>
                      <h5>{addCommas(item.contract_amt)}원</h5>
                    </figure>
                    <figure>
                      <h6>공사기간</h6>
                      <h5>
                        {dayjs(item.begin_day).format("YYYY년 M월 D일")} ~{" "}
                        {dayjs(item.end_day).format("YYYY년 M월 D일")}
                      </h5>
                    </figure>
                    <hr />
                  </div>
                )
              )}

              <figure>
                <h6>발주처(원도급공사)</h6>
                <h5>{detailSite?.orderer_nm}</h5>
              </figure>
              <figure>
                <h6>원도급사(하도급공사)</h6>
                <h5>{detailSite?.contractor_nm}</h5>
              </figure>
              <figure>
                <h6>퇴직공제 가입번호</h6>
                <h5>{detailSite?.retirement_num}</h5>
              </figure>
              <figure>
                <h6>고용/산재 관리번호</h6>
                <h5>{detailSite?.employment_num}</h5>
              </figure>
              <figure>
                <h6>국민/건강 관리번호</h6>
                <h5>{detailSite?.health_num}</h5>
              </figure>
              <figure>
                <h6>관리 부서</h6>
                <h5>
                  {detailSite?.team_seq === 0
                    ? ""
                    : teamsOptions?.find((e) => e.value == detailSite?.team_seq)
                        ?.label}
                </h5>
              </figure>
              <hr />
              <figure>
                <h6>현장소장</h6>
                <h5>
                  {detailSite?.manager_nm
                    ? detailSite?.manager_nm
                    : detailSite?.manager_nm_temp}
                </h5>
              </figure>
              <figure style={{ alignItems: "baseline" }}>
                <h6>현장 주소</h6>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div>
                    {getSiteAddressList?.addressList &&
                      getSiteAddressList?.addressList?.map((e, i) => (
                        <h5 key={i}>
                          {e.addr1} {e.addr2}
                        </h5>
                      ))}
                  </div>
                </div>
              </figure>
              <figure>
                <h6>가상펜스</h6>
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                    width: "calc(100% - 127px)",
                    justifyContent: "space-between",
                  }}
                >
                  {detailSite?.distance} 미터
                  {detailSite?.addr1 && (
                    <Button
                      style={{
                        backgroundColor: "#3279F5",
                        color: "#fff",
                        marginTop: "0",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setOpendLocation(true);
                      }}
                    >
                      현장위치
                    </Button>
                  )}
                </h5>
              </figure>
              <hr />
              <figure>
                <h6>세금계산서 이메일</h6>
                <h5>{detailSite?.tax_email_addr}</h5>
              </figure>
              <figure>
                <h6>세금계산서 담당자</h6>
                <h5>{detailSite?.tax_user_nm}</h5>
              </figure>
              <figure>
                <h6>담당자 전화번호</h6>
                <h5>{detailSite?.tax_phone_num}</h5>
              </figure>
              <hr />
              <figure>
                <h6>평일 오전</h6>
                <h5>
                  {detailSite?.am_begin} ~ {detailSite?.am_end}
                </h5>
              </figure>
              <figure>
                <h6>평일 오후</h6>
                <h5>
                  {detailSite?.pm_begin} ~ {detailSite?.pm_end}
                </h5>
              </figure>
              <figure>
                <h6>토요일 오전</h6>
                <h5>
                  {detailSite?.satday_yn === "Y"
                    ? `${detailSite?.satday_begin} ~ ${detailSite?.satday_end}`
                    : "인정안함"}
                </h5>
              </figure>
              <figure>
                <h6>토요일 오후</h6>
                <h5>
                  {detailSite?.satday_yn === "Y"
                    ? `${detailSite?.satday_pm_begin} ~ ${detailSite?.satday_pm_end}`
                    : "인정안함"}
                </h5>
              </figure>
              <figure>
                <h6>일요일/공휴일 오전</h6>
                <h5>
                  {detailSite?.weekend_yn === "Y"
                    ? `${detailSite?.weekend_begin} ~ ${detailSite?.weekend_end}`
                    : "인정안함"}
                </h5>
              </figure>
              <figure>
                <h6>일요일/공휴일 오후</h6>
                <h5>
                  {detailSite?.weekend_yn === "Y"
                    ? `${detailSite?.weekend_pm_begin} ~ ${detailSite?.weekend_pm_end}`
                    : "인정안함"}
                </h5>
              </figure>
              <figure>
                <h6>야간</h6>
                <h5>
                  {detailSite?.overtime_yn === "Y"
                    ? `${
                        detailSite?.overtime_begin
                          ? detailSite?.overtime_begin
                          : ""
                      } ~ ${
                        detailSite?.overtime_end ? detailSite?.overtime_end : ""
                      }`
                    : "인정안함"}
                </h5>
              </figure>
              <figure>
                <h6>오차범위</h6>
                <h5>
                  {dayjs("0000-00-00 " + detailSite?.time_range).format("mm") +
                    "분"}
                </h5>
              </figure>
              <hr />
            </Content>
          </SideModal>

          <SideModal
            opend={opendWorkTimeSet}
            closeModal={() => {
              setOpendWorkTimeSet(false);
            }}
            header={"근무조건 설정"}
          >
            <Content>
              <h6>근무일/휴일</h6>
              <span style={{ color: "#ff3838", fontSize: "12px" }}>
                * 근무일와 휴일 합이 7일 이어야 합니다
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <h5 style={{ marginBottom: "5px" }}>근무일</h5>
                <div
                  style={{
                    width: "100%",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <SelectedFilterBox
                    placeholder="근무일을 선택해주세요"
                    options={workWeekOptions}
                    value={workWeekOptions.find(
                      (item) => item.value === workWeekSelect
                    )}
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      setWorkWeekSelect(event.value);
                      setWorkWeek({
                        월: false,
                        화: false,
                        수: false,
                        목: false,
                        금: false,
                        토: false,
                        일: false,
                      });
                    }}
                  ></SelectedFilterBox>
                </div>

                <h5 style={{ marginBottom: "5px" }}>휴일</h5>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4,1fr)",
                    rowGap: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "월");
                    }}
                    data={{
                      text: "월요일",
                      isCheck: workWeek["월"],
                    }}
                  />
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "화");
                    }}
                    data={{
                      text: "화요일",
                      isCheck: workWeek["화"],
                    }}
                  />
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "수");
                    }}
                    data={{
                      text: "수요일",
                      isCheck: workWeek["수"],
                    }}
                  />
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "목");
                    }}
                    data={{
                      text: "목요일",
                      isCheck: workWeek["목"],
                    }}
                  />
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "금");
                    }}
                    data={{
                      text: "금요일",
                      isCheck: workWeek["금"],
                    }}
                  />
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "토");
                    }}
                    data={{
                      text: "토요일",
                      isCheck: workWeek["토"],
                    }}
                  />
                  <CustomChecked
                    style={{ fontSize: "14px" }}
                    onChange={(checked) => {
                      handleCheck(checked, "일");
                    }}
                    data={{
                      text: "일요일",
                      isCheck: workWeek["일"],
                    }}
                  />
                </div>
              </div>
              <hr />
              <h6>평일 근무</h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}>오전</h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm="am"
                    value="am_begin"
                    time={modifyTimeData.am_begin}
                    onChange={changeTime}
                    tiemData={{
                      label: "am_start",
                      am_start: modifyTimeData.am_begin,
                      am_end: modifyTimeData.am_end,
                      pm_start: modifyTimeData.pm_begin,
                      pm_end: modifyTimeData.pm_end,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm="am"
                    value="am_end"
                    time={modifyTimeData.am_end}
                    onChange={changeTime}
                    tiemData={{
                      label: "am_end",
                      am_start: modifyTimeData.am_begin,
                      am_end: modifyTimeData.am_end,
                      pm_start: modifyTimeData.pm_begin,
                      pm_end: modifyTimeData.pm_end,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}>오후</h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="pm_begin"
                    time={modifyTimeData.pm_begin}
                    onChange={changeTime}
                    tiemData={{
                      label: "pm_start",
                      am_start: modifyTimeData.am_begin,
                      am_end: modifyTimeData.am_end,
                      pm_start: modifyTimeData.pm_begin,
                      pm_end: modifyTimeData.pm_end,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="pm_end"
                    time={modifyTimeData.pm_end}
                    onChange={changeTime}
                    tiemData={{
                      label: "pm_end",
                      am_start: modifyTimeData.am_begin,
                      am_end: modifyTimeData.am_end,
                      pm_start: modifyTimeData.pm_begin,
                      pm_end: modifyTimeData.pm_end,
                    }}
                    overTimeData={{
                      label: "pm_end",
                      overtime_start:
                        modifyTimeData.overtime_yn === "Y"
                          ? modifyTimeData.overtime_begin
                          : "",
                      pm_end: modifyTimeData.pm_end,
                      satday_end:
                        modifyTimeData.satday_yn === "Y"
                          ? modifyTimeData.satday_pm_end
                          : "",
                      weekend_end:
                        modifyTimeData.weekend_yn === "Y"
                          ? modifyTimeData.weekend_pm_end
                          : "",
                    }}
                  />
                </div>
              </div>

              {/* 토요일 근무 */}
              <div style={{ display: "flex" }}>
                <h6 style={{ minWidth: "40%" }}>토요일 근무</h6>
                <RadioButton>
                  <input
                    type="radio"
                    name="satday_yn"
                    checked={modifyTimeData.satday_yn === "N"}
                    onChange={(e) => changeTime("satday_yn", "N")}
                  />
                  <span className="checkmark" />
                  <h6>인정안함</h6>
                </RadioButton>
                <RadioButton>
                  <input
                    type="radio"
                    name="satday_yn"
                    checked={modifyTimeData.satday_yn === "Y"}
                    onChange={(e) => changeTime("satday_yn", "Y")}
                  />
                  <span className="checkmark" />
                  <h6>인정함</h6>
                </RadioButton>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}>오전</h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm="am"
                    value="satday_begin"
                    time={modifyTimeData.satday_begin}
                    disabled={modifyTimeData.satday_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "am_start",
                      am_start: modifyTimeData.satday_begin,
                      am_end: modifyTimeData.satday_end,
                      pm_start: modifyTimeData.satday_pm_begin,
                      pm_end: modifyTimeData.satday_pm_end,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm="am"
                    value="satday_end"
                    time={modifyTimeData.satday_end}
                    disabled={modifyTimeData.satday_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "am_end",
                      am_start: modifyTimeData.satday_begin,
                      am_end: modifyTimeData.satday_end,
                      pm_start: modifyTimeData.satday_pm_begin,
                      pm_end: modifyTimeData.satday_pm_end,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}>오후</h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="satday_pm_begin"
                    time={modifyTimeData.satday_pm_begin}
                    disabled={modifyTimeData.satday_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "pm_start",
                      am_start: modifyTimeData.satday_begin,
                      am_end: modifyTimeData.satday_end,
                      pm_start: modifyTimeData.satday_pm_begin,
                      pm_end: modifyTimeData.satday_pm_end,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="satday_pm_end"
                    time={modifyTimeData.satday_pm_end}
                    disabled={modifyTimeData.satday_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "pm_end",
                      am_start: modifyTimeData.satday_begin,
                      am_end: modifyTimeData.satday_end,
                      pm_start: modifyTimeData.satday_pm_begin,
                      pm_end: modifyTimeData.satday_pm_end,
                    }}
                    overTimeData={{
                      label: "satday_end",
                      overtime_start:
                        modifyTimeData.overtime_yn === "Y"
                          ? modifyTimeData.overtime_begin
                          : "",
                      pm_end: modifyTimeData.pm_end,
                      satday_end:
                        modifyTimeData.satday_yn === "Y"
                          ? modifyTimeData.satday_pm_end
                          : "",
                      weekend_end:
                        modifyTimeData.weekend_yn === "Y"
                          ? modifyTimeData.weekend_pm_end
                          : "",
                    }}
                  />
                </div>
              </div>

              {/* 일요일/공휴일 근무 */}
              <div style={{ display: "flex" }}>
                <h6 style={{ minWidth: "40%" }}>일요일/공휴일 근무</h6>
                <RadioButton>
                  <input
                    type="radio"
                    name="weekend_yn"
                    checked={modifyTimeData.weekend_yn === "N"}
                    onChange={(e) => changeTime("weekend_yn", "N")}
                  />
                  <span className="checkmark" />
                  <h6>인정안함</h6>
                </RadioButton>
                <RadioButton>
                  <input
                    type="radio"
                    name="weekend_yn"
                    checked={modifyTimeData.weekend_yn === "Y"}
                    onChange={(e) => changeTime("weekend_yn", "Y")}
                  />
                  <span className="checkmark" />
                  <h6>인정함</h6>
                </RadioButton>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}>오전</h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm="am"
                    value="weekend_begin"
                    time={modifyTimeData.weekend_begin}
                    disabled={modifyTimeData.weekend_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "am_start",
                      am_start: modifyTimeData.weekend_begin,
                      am_end: modifyTimeData.weekend_end,
                      pm_start: modifyTimeData.weekend_pm_begin,
                      pm_end: modifyTimeData.weekend_pm_end,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm="am"
                    value="weekend_end"
                    time={modifyTimeData.weekend_end}
                    disabled={modifyTimeData.weekend_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "am_end",
                      am_start: modifyTimeData.weekend_begin,
                      am_end: modifyTimeData.weekend_end,
                      pm_start: modifyTimeData.weekend_pm_begin,
                      pm_end: modifyTimeData.weekend_pm_end,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}>오후</h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="weekend_pm_begin"
                    time={modifyTimeData.weekend_pm_begin}
                    disabled={modifyTimeData.weekend_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "pm_start",
                      am_start: modifyTimeData.weekend_begin,
                      am_end: modifyTimeData.weekend_end,
                      pm_start: modifyTimeData.weekend_pm_begin,
                      pm_end: modifyTimeData.weekend_pm_end,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="weekend_pm_end"
                    time={modifyTimeData.weekend_pm_end}
                    disabled={modifyTimeData.weekend_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "pm_end",
                      am_start: modifyTimeData.weekend_begin,
                      am_end: modifyTimeData.weekend_end,
                      pm_start: modifyTimeData.weekend_pm_begin,
                      pm_end: modifyTimeData.weekend_pm_end,
                    }}
                    overTimeData={{
                      label: "weekend_end",
                      overtime_start:
                        modifyTimeData.overtime_yn === "Y"
                          ? modifyTimeData.overtime_begin
                          : "",
                      pm_end: modifyTimeData.pm_end,
                      satday_end:
                        modifyTimeData.satday_yn === "Y"
                          ? modifyTimeData.satday_pm_end
                          : "",
                      weekend_end:
                        modifyTimeData.weekend_yn === "Y"
                          ? modifyTimeData.weekend_pm_end
                          : "",
                    }}
                  />
                </div>
              </div>

              {/* 야간 근무 */}
              <div style={{ display: "flex" }}>
                <h6 style={{ minWidth: "30%" }}>야간 근무</h6>
                <RadioButton>
                  <input
                    type="radio"
                    name="overtime_yn"
                    checked={modifyTimeData.overtime_yn === "N"}
                    onChange={(e) => {
                      changeTime("overtime_yn", "N");
                      setModifyTimeData((prevState) => ({
                        ...prevState,
                        overnight_yn: "N",
                      }));
                    }}
                  />
                  <span className="checkmark" />
                  <h6>인정안함</h6>
                </RadioButton>
                <RadioButton className="label">
                  <input
                    type="radio"
                    name="overtime_yn"
                    checked={modifyTimeData.overtime_yn === "Y"}
                    onChange={(e) => changeTime("overtime_yn", "Y")}
                    style={{ marginRight: "0" }}
                  />
                  <span className="checkmark" />
                  <h6>인정함{" ("}</h6>
                </RadioButton>

                <Checked
                  checked={modifyTimeData?.overnight_yn === "Y" ? true : false}
                  icon={
                    modifyTimeData?.overnight_yn === "Y" && (
                      <CheckboxIcon>
                        <CheckboxIconInner>
                          <CheckboxIconImage src="/assets/icons/ignore.svg" />
                        </CheckboxIconInner>
                      </CheckboxIcon>
                    )
                  }
                  borderColor={
                    modifyTimeData?.overnight_yn === "Y" ? "#3279F5" : "#E2E8F0"
                  }
                  style={{
                    overflow: "hidden",
                    width: "13px",
                    height: "13px",
                    zIndex: "999",
                    borderRadius: "3px",
                    marginLeft: "5px",
                  }}
                  onChange={(checked) => {
                    if (checked) {
                      changeTime("overtime_yn", "Y");
                    }
                    setModifyTimeData((prevState) => ({
                      ...prevState,
                      overnight_yn: checked ? "Y" : "N",
                      overtime_end: checked
                        ? "00:00:00"
                        : prevState.overtime_end,
                    }));
                  }}
                  label={
                    <div
                      style={{
                        cursor: "pointer",
                        color: "#718096",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="label"
                    >
                      {`철야 )`}
                    </div>
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <h5 style={{ minWidth: "30px" }}></h5>
                <div style={{ width: "100%", marginRight: "10px" }}>
                  <a>시작 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="overtime_begin"
                    time={modifyTimeData.overtime_begin}
                    disabled={modifyTimeData.overtime_yn === "N"}
                    onChange={changeTime}
                    tiemData={{
                      label: "overtime_start",
                      am_start: modifyTimeData.satday_begin,
                      am_end: modifyTimeData.satday_end,
                      pm_start: modifyTimeData.satday_pm_begin,
                      pm_end: modifyTimeData.satday_pm_end,
                    }}
                    overTimeData={{
                      label: "overtime_start",
                      overtime_start:
                        modifyTimeData.overtime_yn === "Y"
                          ? modifyTimeData.overtime_begin
                          : "",
                      pm_end: modifyTimeData.pm_end,
                      satday_end:
                        modifyTimeData.satday_yn === "Y"
                          ? modifyTimeData.satday_pm_end
                          : "",
                      weekend_end:
                        modifyTimeData.weekend_yn === "Y"
                          ? modifyTimeData.weekend_pm_end
                          : "",
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <a>종료 시간</a>
                  <CustomTimePicker
                    ampm={"pm"}
                    value="overtime_end"
                    time={modifyTimeData.overtime_end}
                    disabled={modifyTimeData.overtime_yn === "N"}
                    workNightTime={modifyTimeData?.overnight_yn === "Y"}
                    onChange={changeTime}
                  />
                </div>
              </div>

              <h6 style={{ minWidth: "40%" }}>오차 범위</h6>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <input
                  style={{ width: "100px", height: "30px", padding: "0 10px" }}
                  maxLength={2}
                  value={modifyTimeData.time_range}
                  onChange={(e) => {
                    if (e.target.value.match(/[^0-9]/g)) {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }
                    if (e.target.value.length > 2) {
                      e.target.value = e.target.value.slice(0, 2);
                    }
                    changeTime("time_range", e.target.value);
                  }}
                />
                <h5 style={{ marginTop: "10px", marginLeft: "10px" }}>분</h5>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ backgroundColor: "#3279F5", color: "#fff" }}
                  onClick={() => {
                    const validate = validateTime();
                    if (validate) {
                      requestModifyTime();
                    }
                  }}
                >
                  저장하기
                </Button>
              </div>
            </Content>
          </SideModal>

          <Modal
            opend={opendModifySite}
            closeModal={(e) => {
              setOpendModifySite(modalClose.current);
              modalClose.current = false;
            }}
            noCloseModal={false}
            header={"현장 정보 수정"}
            okText={"수정하기"}
            formRef={formRef}
            style={{ width: "90%" }}
            // handleOk={() => handleModifySite()}
          >
            <ModifySiteForm
              ref={formRef}
              data={{
                endNtabColor: endNtabColor,
                endYtabColor: endYtabColor,
                workFieldOptions: workFieldOptions,
                teamsOptions: teamsOptions,
                getSiteAddressList: getSiteAddressList,
                detailSite: detailSite,
                managerData: managerData,
                formFields: formFields,
              }}
              state={{
                setCancelSelectSiteManager: setCancelSelectSiteManager,
                setOpendSelectSiteManager: setOpendSelectSiteManager,
                setOpenMapMode: setOpenMapMode,
                setOpendMap: setOpendMap,
              }}
              handle={{
                handleEndYnTab: handleEndYnTab,
                addressDelete: addressDelete,
                handleModifySite: handleModifySite,

                handleManagerChange: handleManagerChange,
              }}
            ></ModifySiteForm>
          </Modal>

          <Modal
            opend={opendNewSite}
            closeModal={() => {
              setOpendNewSite(modalClose.current);
              modalClose.current = false;
            }}
            header={"현장 정보 등록"}
            okText={"등록하기"}
            formRef={formRef}
            // handleOk={handleNewSite}
            style={{ width: "90%" }}
          >
            <NewSiteForm
              ref={formRef}
              data={{
                workFieldOptions: workFieldOptions,
                teamsOptions: teamsOptions,
                initPopupData: initPopupData,
                addr: addr,
                subAddr: subAddr,
                managerData: managerData,
              }}
              state={{
                setOpendMap: setOpendMap,
                setOpendSelectSiteManager: setOpendSelectSiteManager,
                setOpenMapMode: setOpenMapMode,
                setCancelSelectSiteManager: setCancelSelectSiteManager,
              }}
              handleNewSite={handleNewSite}
            ></NewSiteForm>
          </Modal>
          <Modal
            opend={opendMap}
            // formRef={childRef}
            closeModal={(e) => {
              if (e) {
                setOpendMap(false); // 취소버튼 클릭시에만 map 팝업 닫음
              }
            }}
            header={`${
              openMapMode.modeName === "modify" ? "주소 수정" : "주소 등록"
            }`}
            okText={"확인"}
            widthCheck={"800px"}
            handleOk={() => childRef.current.setAddrData()}
          >
            {/* <KakaoMap
            setAddrInfo={handleAddrInfo}
            ref={childRef}
            openMapMode={openMapMode}
            setOpendMap={setOpendMap}
            addressList={getSiteAddressList?.addressList}
          ></KakaoMap> */}
            <NaverMap
              setAddrInfo={handleAddrInfo}
              ref={childRef}
              openMapMode={openMapMode}
              setOpendMap={setOpendMap}
              addressList={getSiteAddressList?.addressList}
            ></NaverMap>
          </Modal>

          <PopUpModal
            opend={opendSelectSiteManager}
            closeModal={() => {
              setOpendSelectSiteManager(false);
            }}
            header={"현장소장 등록"}
            okText={""}
            cancelText={""}
            handleOk={() => {}}
            widthCheck={"40%"}
          >
            <Content>
              <Field>현장소장을 등록해주세요</Field>
              <br />
              <Field>
                <h5>이름</h5>
                <input
                  style={{ width: "250px", minWidth: "250px" }}
                  name="site_manager_nm"
                />
              </Field>

              <h5>휴대전화</h5>
              <Field
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  style={{ width: "70px", minWidth: "70px" }}
                  name="site_manager_phone_num1"
                  type="text"
                  maxLength={"3"}
                  defaultValue={"010"}
                  readOnly={true}
                  onChange={(e) => {
                    numberOnly(e);
                    if (e.target.value.length >= e.target.maxLength) {
                      const nextInput = e.target.nextElementSibling;
                      if (nextInput) {
                        nextInput.focus();
                      }
                    }
                  }}
                />
                -
                <input
                  style={{ width: "70px", minWidth: "70px" }}
                  name="site_manager_phone_num2"
                  type="text"
                  maxLength={"4"}
                  onChange={(e) => {
                    numberOnly(e);
                    if (e.target.value.length >= e.target.maxLength) {
                      const nextInput = e.target.nextElementSibling;
                      if (nextInput) {
                        nextInput.focus();
                      }
                    }
                  }}
                />
                -
                <input
                  style={{ width: "70px", minWidth: "70px" }}
                  name="site_manager_phone_num3"
                  type="text"
                  maxLength={"4"}
                  onChange={(e) => {
                    numberOnly(e);
                    if (e.target.value.length >= e.target.maxLength) {
                      const nextInput = e.target.nextElementSibling;
                      if (nextInput) {
                        nextInput.focus();
                      }
                    }
                  }}
                />
                <Button
                  style={{
                    display: "inline-block",
                    minWidth: "70px",
                    height: "35px",
                    textAlign: "center",
                    alignContent: "center",
                    backgroundColor: "#708090",
                    color: "aliceblue",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                  onClick={async () => {
                    await handleFindManager();
                  }}
                >
                  소장 검색
                </Button>
              </Field>
            </Content>
          </PopUpModal>

          <PopUpModal
            opend={opendSiteManagerPopup}
            closeModal={() => {
              if (popUpData?.response) {
                setOpendSelectSiteManager(false);
              }
              setCancelSelectSiteManager(true);
              setOpendSiteManagerPopup(false);
            }}
            header={"현장소장 등록"}
            okText={"확인"}
            cancelText={"취소"}
            handleOk={() => {
              if (popUpData?.response) {
                handleManagerChange();
                setOpendSelectSiteManager(false);
              }
              setOpendSiteManagerPopup(false);
            }}
            widthCheck={"30%"}
            heightCheck={"300px"}
          >
            <Content
              style={{
                whiteSpace: "pre-wrap",
                display: "flex",
                alignItems: !popUpData?.userData ? "center" : "normal",
                height: !popUpData?.userData ? "100%" : "unset",
              }}
            >
              {popUpData.content}
            </Content>
            {popUpData?.userData && <br />}
            {popUpData?.userData != null ? (
              <LaborCard>
                <Avatar
                  style={{
                    marginRight: "3px",
                    width: "64px",
                    height: "64px",
                    backgroundImage: `url(${
                      popUpData?.userData.photo || "/assets/images/profile.png"
                    })`,
                  }}
                />
                <LaborCardContent>
                  {/*<h5>{JSON.stringify(popUpData)}</h5>*/}
                  <h4>
                    {popUpData?.userData.user_nm} /{" "}
                    {popUpData?.userData.phone_num}
                  </h4>
                </LaborCardContent>
              </LaborCard>
            ) : (
              // <LaborCard>
              <div />
              // </LaborCard>
            )}
          </PopUpModal>

          <PlanSelectModal
            opend={openPlanSelect}
            closeModal={() => {
              setOpenPlanSelect(false);
            }}
            handleOk={() => {}}
            widthCheck={"50%"}
            maxHeightCheck={"797"}
          >
            <div></div>
          </PlanSelectModal>

          {/* 현장 위치 팝업 */}
          <Modal
            opend={opendLocation}
            closeModal={() => setOpendLocation(false)}
            header={`현장 위치`}
            cancelOnly={true}
            okText={"닫기"}
            cancelText={""}
            widthCheck={"800px"}
          >
            <NaverMapOverlay siteSeq={detailSite?.site_seq}></NaverMapOverlay>
          </Modal>
        </FormProvider>
      </div>
    </AxiosNavigate>
  );
}
const Checked = styled(Checkbox)`
  z-index: 9999;
  position: relative;
`;
export const FormButton = styled.div`
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 8px;
  background-color: #708090;
  color: aliceblue;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  //width: fit-content;
  margin-top: 12px;
  margin-bottom: 15px;
`;
const CheckboxIcon = styled.div`
  display: flex;
  flex: 1;
  background-color: #3279f5;
  align-self: stretch;
  cursor: pointer;
`;

const CheckboxIconInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxIconImage = styled.img`
  width: 8px;
  height: 8px;
`;
const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
  margin-right: 16px;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 6px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background: #fff;
  /* box-sizing: border-box; */
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 12px;
  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 25px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    //background-color: #F7FAFC;
  }

  tr:last-child td {
    border-bottom: none;
  }

  thead {
    height: 31px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;

const Content = styled.div`
  figure {
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 127px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  a {
    color: rgba(0, 0, 0, 0.92);
    font-size: 12px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {
    }
  }

  input[type="checkbox"] {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #edf2f7;
  padding: 4px;
  margin-bottom: 28px;
`;

const Tab = styled.div`
  width: 50%;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
`;
const NoCursorTab = styled.div`
  width: 50%;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
`;

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 14px;
`;

const Field = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  span {
    color: #ff3838;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {
    }
  }

  width: ${({ fulled }) => (fulled ? `100%` : "48%")};

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-left: 8px;
`;
const Card = styled.div`
  border-radius: 12px;
  background: #f7fafc;
  padding: 20px;
  margin-top: 20px;

  h5 {
    color: #171923;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    margin-bottom: 16px;
  }
`;

const LaborCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0;

  //&:hover {
  //    background-color: #EBF2FE !important;
  //    border-radius: 4px;
  //}
`;

const LaborCardContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  h6 {
    color: #718096;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
const EmptyLaborCard = styled.div`
  color: #4a5568;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding-top: 26px;
`;

const RadioButton = styled.label`
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  margin-right: 20px;
  &&.label {
    width: unset;
    margin-right: 0px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
  }

  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }

  h6 {
    width: 100%;
  }
`;

export default Site;
