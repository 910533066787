import React, { forwardRef, useEffect } from "react";
import styled from "@emotion/styled";
import { createMarkup } from "../../bin/common";

const OwnEmploymentContractPrint = forwardRef((props, ref) => {
  const { data, writeData } = props;
  useEffect(() => {}, []);
  return (
    <Container ref={ref}>
      <Contract
        dangerouslySetInnerHTML={createMarkup(data.contract)}
      ></Contract>
    </Container>
  );
});

const Container = styled.div`
  //background: #FFF;
  //padding: 20px 24px;

  table td:not(.value),
  table th {
    //padding: 2px 0 !important;
  }

  table .value {
    align-items: center;
  }

  @page {
    size: A4 portrait !important;
    //margin-top: 20px;
    //margin-bottom: 25px;
    margin: 0 !important;
    padding: 0 !important;
  }

  @media print {
    padding: 0 !important;
    margin: 0 !important;
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    .table-wrapper {
      //break-inside: auto;
      break-after: page;
    }

    .signature .value {
      height: 100% !important;
    }
  }

  .container {
    min-width: 990px;
  }

  .signature .value {
    height: 30px !important;
    box-sizing: border-box;
  }

  .detail {
    line-height: 15px;
  }
`;

const Contract = styled.div``;

export default OwnEmploymentContractPrint;
