import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { REACT_APP_ADDRESS_API_KEY } from "../bin/env.js";
import { components } from "react-select";
const NaverMap = forwardRef((props, ref) => {
  const { addressList, openMapMode } = props;
  const mapRef = useRef(null);
  const componentRef = useRef(null);
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState("");
  const [addr2, setAddr2] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [logitude, setLogitude] = useState(
    openMapMode?.items?.logitude ? openMapMode?.items?.logitude : 126.9784229
  ); //세로
  const [latitude, setLatitude] = useState(
    openMapMode?.items?.latitude ? openMapMode?.items?.latitude : 37.5683629
  );
  const [mapInfo, setMapInfo] = useState(openMapMode?.items);
  const [addressItems, setAddressItems] = useState([]);
  const [addressIndex, setAddressIndex] = useState(1);
  const [hasScrolled, setHasScrolled] = useState(false); // 스크롤 조건 충족 여부

  // var position = new window.naver.maps.LatLng(37.5683629, 126.9784229);
  useEffect(() => {
    if (window.naver && window.naver.maps) {
      initializeMap();
    } else {
      console.error("Naver Maps API가 로드되지 않았습니다.");
    }
  }, []);

  const initializeMap = () => {
    const map = new window.naver.maps.Map(mapRef.current, {
      center: new window.naver.maps.LatLng(latitude, logitude),
      zoom: 17,
      zoomControl: false,
      logoControl: false,
    });
    const infoWindow = new window.naver.maps.InfoWindow({
      anchorSkew: true,
    });
    map.setCursor("pointer");
    setMap(map);
    setInfoWindow(infoWindow);

    var marker = new window.naver.maps.Marker({
      position: new window.naver.maps.LatLng(latitude, logitude),
      map: map,
    });
    setMarker(marker);

    map?.addListener("click", (e) => {
      marker.setPosition(e.coord);
      searchCoordinateToAddress(e.latlng);
    });
  };

  useEffect(() => {
    // initializeMap();
  }, [latitude, logitude, address]);

  const searchCoordinateToAddress = (latlng) => {
    window.naver.maps.Service.reverseGeocode(
      {
        coords: new window.naver.maps.LatLng(latlng.y, latlng.x),
        orders: [
          window.naver.maps.Service.OrderType.ADDR,
          window.naver.maps.Service.OrderType.ROAD_ADDR,
        ].join(","),
      },
      function (status, response) {
        if (status !== window.naver.maps.Service.Status.OK) {
          return alert("Something wrong!");
        }

        var result = response.v2, // 검색 결과의 컨테이너
          items = result.results, // 검색 결과의 배열
          address = result.address; // 검색 결과로 만든 주소

        setAddress(
          !address?.roadAddress
            ? address?.jibunAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
            : address?.roadAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
        );

        handleSearchAddress(
          !address?.roadAddress ? address?.jibunAddress : address?.roadAddress
        );
      }
    );
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSearchAddress = (address) => {
    if (!address) return;

    window?.naver?.maps?.Service?.geocode(
      {
        query: address,
      },
      function (status, response) {
        if (status !== window.naver.maps.Service.Status.OK) {
          return alert("Something wrong!");
        }
        var result = response.v2, // 검색 결과의 컨테이너
          items = result.addresses; // 검색 결과의 배열

        if (response.v2.meta.totalCount !== 1) {
          handleSearchAddressList(address);
          setHasScrolled(false);
          return;
        }

        const point = new window.naver.maps.Point(items[0].x, items[0].y);
        setAddressItems([]);
        setZoneCode(items[0]?.addressElements[8].longName);
        setLogitude(Number(items[0]?.x).toFixed(10));
        setLatitude(Number(items[0]?.y).toFixed(10));
        setAddress(
          !items[0]?.roadAddress
            ? items[0]?.jibunAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
            : items[0]?.roadAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
        );
        map?.setCenter(point);
        marker?.setPosition(items[0]);
      }
    );
  };
  useEffect(() => {
    if (openMapMode?.modeName === "modify") {
      setAddress(openMapMode?.items?.addr1);
      setAddr2(openMapMode?.items?.addr2);
      setZoneCode(openMapMode?.items?.postal_cd);

      handleSearchAddress(openMapMode?.items?.addr1);
    }
  }, [openMapMode]);

  // 부모 컴포넌트로 데이터 넘기기 위한 함수
  const setAddrData = () => {
    const subAddr = document.getElementById("addr2").value;
    if (props.hasOwnProperty("setAddrInfo")) {
      if (address == "") {
        alert("주소를 입력해 주세요");
        return;
      }

      const isDuplicate = addressList?.some(
        (item) =>
          item.latitude === latitude &&
          item.logitude === logitude &&
          (item.addr2 || "") === (addr2 || "")
      );

      if (isDuplicate) {
        alert("중복된 주소가 있습니다.");
        return;
      }

      props.setOpendMap(false);
      props.setAddrInfo(
        address,
        subAddr,
        zoneCode,
        logitude,
        latitude,
        props.openMapMode
      );
    }
  };
  useImperativeHandle(ref, () => ({
    setAddrData,
  }));

  const handleSearchAddressList = useCallback(
    async (address, index) => {
      try {
        const currentPage = hasScrolled ? addressIndex + 1 : 1;

        const {
          data: {
            results: { juso, common },
          },
        } = await axios.post(
          `https://business.juso.go.kr/addrlink/addrLinkApi.do?confmKey=${REACT_APP_ADDRESS_API_KEY}&currentPage=${currentPage}&countPerPage=50&keyword=${address}&hstryYn=Y&firstSort=road&resultType=json`
        );

        if (common.errorCode !== "0") {
          if (!hasScrolled) {
            alert(common.errorMessage);
            setAddressItems([]);
          }
          return;
        }

        if (!hasScrolled) {
          setAddressIndex(1);
          setAddressItems(juso);
        } else {
          setAddressIndex((prevState) => prevState + 1);
          setAddressItems((prevState) => [...prevState, ...juso]);
        }

        setHasScrolled(false);
      } catch (error) {
        console.log("API 요청 실패:", error);
        setHasScrolled(false);
      }
    },
    [addressIndex, hasScrolled] // 의존성 배열
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // 여기서 원하는 작업을 수행
        setAddressItems([]);
      }
    }

    // 마운트 시 document에 이벤트 리스너 추가
    document.addEventListener("mousedown", handleClickOutside);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef]);

  useEffect(() => {
    if (!componentRef.current) return;
    const scrollElement = componentRef.current;
    // 페이지가 렌더링 되면 무조건 맨위로 스크롤 한다.

    const handleScroll = () => {
      // 일정 구간 스크롤이 내려가면 버튼을 보여준다.
      if (scrollElement) {
        const scrollTop = scrollElement.scrollTop;
        const scrollHeight = scrollElement.scrollHeight;
        const clientHeight = scrollElement.clientHeight;

        if (
          addressItems?.length !== 0 &&
          !hasScrolled &&
          scrollTop > ((scrollHeight - clientHeight) / 3) * 2
        ) {
          setHasScrolled(true);

          // handleSearchAddressList(address);
        }
      }
    };

    // window에 scroll 이벤트를 넣는다.
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    // 페이지를 벗어날 때 이벤트를 제거한다.
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [addressItems, hasScrolled, address]);

  useEffect(() => {
    if (hasScrolled) {
      handleSearchAddressList(address);
    }
  }, [hasScrolled, address, addressIndex]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          position: "relative",
        }}
      >
        <div style={{ width: "92%", position: "relative" }}>
          <Input
            id={"addr"}
            placeholder={
              "주소 또는 동으로 검색 후, 지도에서 위치를 선택해 주세요"
            }
            value={address || ""}
            style={{
              width: "100%",
              borderRadius: addressItems?.length > 0 ? "6px 6px 0 0" : "6px",
            }}
            onChange={handleAddressChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                if (address === "") {
                  alert("주소가 옳바르지 않습니다. 다시 확인해 주세요");
                  setAddressItems([]);
                  return;
                }

                handleSearchAddress(address);
              }
            }}
          />
          {addressItems?.length > 0 && (
            <ListWrapper ref={componentRef}>
              {addressItems.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setAddressItems([]);
                    handleSearchAddress(item.roadAddr);
                  }}
                >
                  <span>
                    <em>도로명</em>
                    {item.roadAddr}
                  </span>
                  <span>
                    <em>지 번</em>
                    {item.jibunAddr}
                  </span>
                </li>
              ))}
            </ListWrapper>
          )}
        </div>
        <Button
          onClick={(e) => {
            if (address === "") {
              alert("주소가 옳바르지 않습니다. 다시 확인해 주세요");
              setAddressItems([]);
              return;
            }
            handleSearchAddress(address);
          }}
        >
          검색
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Input
          id="addr2"
          maxLength={50}
          placeholder={"상세 주소를 입력해 주세요"}
          value={addr2 || ""}
          style={{ width: "100%" }}
          onChange={(e) => setAddr2(e.target.value)}
        />
      </div>
      <NaverMapBox ref={mapRef}></NaverMapBox>
    </div>
  );
});
const ListWrapper = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  top: 36px;
  width: 100%;
  border: 1px solid #cbd5e0;
  /* padding: 5px 0; */
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  max-height: 500px;
  background-color: #fff;
  overflow: auto;
  z-index: 99;
  li {
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    border-bottom: 1px solid #cbd5e0;
    span {
      font-size: 14px;
    }
    em {
      margin-right: 7px;
      border: 1px solid #d4e2ef;
      border-radius: 1px;
      font-size: 11px;
      width: 36px;
      height: 17px;
      line-height: 17px;
      padding: 0px 1px;
      display: inline-block;
      box-sizing: border-box;
      border-radius: 1px;
      color: #008bd3;
      text-align: center;
    }

    &:last-of-type {
      border-bottom: 1px solid transparent;
    }

    &:hover {
      background-color: #f8f9fa;
    }
  }
`;
const NaverMapBox = styled.div`
  width: 750px;
  height: 500px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    height: calc(100vw - 48px);
  }
`;
const Input = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  appearance: none;
  box-sizing: border-box;
  width: 90%;
  border: 0px;
  outline: none;
  padding: 8px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  margin-bottom: 12px;

  :focus {
  }
`;
const Button = styled.button`
  display: flex;
  height: 37px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3279f5;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
`;
export default NaverMap;
