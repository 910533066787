import React, { useCallback, useEffect, useRef, useState } from "react";
import MonthCalendar from "../../compontents/MonthCalendar";
import styled from "@emotion/styled";
import ButtonComponent from "../../compontents/Button";
import { useRecoilValue } from "recoil";
import { outsourcingCompanyState } from "../../library/recoil/atoms/outsourcingCompanyState";
import { useMutation } from "@tanstack/react-query";
import {
  createSubPartnerPayment,
  deleteSubPartnerPayment,
  getSubPartnerPayment,
  modifyCommuteRecord,
  modifySubPartnerPayment,
} from "../../library/axios-query";
import Modal from "../../compontents/Modal";
import moment from "moment";
import { useForm } from "react-hook-form";
import { InputBox } from "../../library/styled-components";
import { addCommas, createMarkup, numberOnly } from "../../bin/common";
import ImageUploader from "react-image-upload";
import axios from "axios";
import { HOST_URL } from "../../bin/env";
import axiosInstance from "../../library/axios-index";
import EmptyContainer from "../../compontents/EmptyContainer";
import Loading from "../../compontents/Loading";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import DayCalendarPicker from "../../compontents/DayCalendarPicker.js";

const fieldsOrder = ["pay_dt", "pay_amt", "pay_acct", "pay_desc", "phone"];

const OutsourcingHistory = (props) => {
  const { handleHistoryChange, handleHistoryModify } = props;
  const formRef = useRef();
  const inputRef = useRef();
  const tableRef = useRef(null);
  const outsourcingState = useRecoilValue(outsourcingCompanyState);
  const [tabsIndex, setTabsIndex] = useState(0);
  const [openedImages, setOpenedImages] = useState(false);
  const [openedPaymentApply, setOpenedPaymentApply] = useState(false);
  const [isReport, setIsReport] = useState({});

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-01")
  );

  const [openedFormCreate, setOpenedFormCreate] = useState(false); // true => 추가 false => 수정
  const [openedSubmitPayment, setOpenedSubmitPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payImages, setPayImages] = useState([]);

  const {
    register,
    control,
    getValues,
    handleSubmit,
    setFocus,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  // 현장 협력사 목록
  const { mutate: getPayment, data: getSubPartnerPaymentData } = useMutation({
    mutationKey: "getSubPartnerPayment",
    mutationFn: getSubPartnerPayment,
    onSuccess: (data) => {},
    onError: (error) => {
      throw error;
    },
  });

  // 현장 협력사 목록 추가
  const { mutate: createPayment } = useMutation({
    mutationKey: "createSubPartnerPayment",
    mutationFn: createSubPartnerPayment,
    onSuccess: (data) => {
      if (data.done) {
        loadReport();

        setOpenedPaymentApply(false);
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 협력사 삭제
  const { mutate: deletePayment } = useMutation({
    mutationKey: "deleteSubPartnerPayment",
    mutationFn: deleteSubPartnerPayment,
    onSuccess: (data) => {
      if (data.done) {
        loadReport();
        //  getPayment(outsourcingState);
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 협력사 수정
  const { mutate: modifyPayment } = useMutation({
    mutationKey: "modifySubPartnerPayment",
    mutationFn: modifySubPartnerPayment,
    onSuccess: (data) => {
      if (data.done) {
        loadReport();
        setTimeout(() => {
          setOpenedPaymentApply(false);
        }, 300);
      }
    },
    onError: (error) => {
      throw error;
    },
  });
  // 근로자 공수 수정 및 생성(외주 관리 용)
  const { mutate: modifyCommuteRecordMutate } = useMutation({
    mutationKey: "modifyCommuteRecord",
    mutationFn: modifyCommuteRecord,
    onSuccess: (data) => {
      if (data.done) {
        setOpenedSubmitPayment(false);
        loadReport();
        const tableElements = document.querySelectorAll(
          "#report-table tbody td.pointer"
        );
        tableElements.forEach((element) =>
          element.classList.remove("selectDay")
        );
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  const handleOpenPrintModal = useCallback(() => {
    alert("준비중 입니다");
  }, []);

  const handleExcel = () => {
    alert("준비중 입니다");
  };

  const loadReport = useCallback(async () => {
    handleHistoryModify(outsourcingState?.partner_seq);
    const items = {
      site_seq: outsourcingState?.site_seq,
      partner_seq: outsourcingState?.partner_seq,
      work_dt: selectedDate,
    };

    getPayment(items);

    const token = localStorage.getItem("admin_token");

    const request = {
      token,
      site_seq: outsourcingState?.site_seq,
      work_dt: selectedDate,
      belongFilter: outsourcingState?.partner_seq,
      belongType: outsourcingState?.partner_cd,
    };

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/laborPaymentStatementList`, request);

    setIsReport(result);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [selectedDate]);

  useEffect(() => {
    setLoading(true);
    loadReport();
  }, [selectedDate]);

  const handlePaymentApply = () => {
    if (isReport?.is_editable === "N") {
      alert("마감 되었습니다.");
      return;
    }
    reset();
    setPayImages([]);
    setOpenedPaymentApply(true);
    setOpenedFormCreate(true);
  };

  const handleDeleteHistory = (value) => {
    const item = {
      site_seq: value.site_seq,
      pay_seq: Number(value.pay_seq),
      partner_seq: value.partner_seq,
    };
    deletePayment(item);
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));
      setFocus(firstError);

      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  const onSubmit = (data) => {
    const items = {
      site_seq: outsourcingState?.site_seq,
      partner_seq: outsourcingState?.partner_seq,
      pay_dt: data.pay_dt,
      pay_amt: Number(data.pay_amt.replaceAll(",", "")),
      pay_acct: data.pay_acct,
      pay_desc: data.pay_desc,
      image: payImages,
    };

    if (openedFormCreate) {
      // 추가
      createPayment(items);
    } else {
      // 수정
      items.pay_seq = Number(selectedReport?.pay_seq);
      modifyPayment(items);
    }
  };

  const handleDeleteFile = (formName) => {
    setPayImages((prevImages) => {
      return prevImages?.filter(
        (e) =>
          e?.pay_img?.trim().toLowerCase() !== formName?.trim().toLowerCase()
      );
    });
  };
  const handleUploadFile = async (formName, e) => {
    const formData = new FormData();
    formData.append("files", e.file);

    const {
      data: { result },
    } = await axiosInstance.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const img = result[0];
    setPayImages((prevState) => [...prevState, { pay_img: img }]);
    // setValue(formName, img);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      const tableElement = document.querySelector(
        "#report-table tbody td.pointer"
      );
      const tableElements = document.querySelectorAll(
        "#report-table tbody td.pointer"
      );

      if (
        tableRef.current &&
        !tableRef.current.contains(event.target) &&
        tableElement &&
        !tableElement.contains(event.target)
      ) {
        // 여기서 원하는 작업을 수행
        setOpenedSubmitPayment(false);
        tableElements.forEach((element) =>
          element.classList.remove("selectDay")
        );
      }
    }

    // 마운트 시 document에 이벤트 리스너 추가
    document.addEventListener("mousedown", handleClickOutside);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tableRef]);
  const handleOpenComponent = useCallback(
    (event, value, day) => {
      event.stopPropagation();
      if (isReport?.is_editable === "N") {
        return;
      }

      if (event.target.className.toString().includes("grayColor")) {
        return;
      }

      // 클릭한것에 클레스 이름 추가
      const tableElements = document.querySelectorAll(
        "#report-table tbody td.pointer"
      );
      tableElements.forEach((element) => element.classList.remove("selectDay"));
      event.target.classList.add("selectDay");

      setOpenedSubmitPayment(true);
      const selectBox = tableRef.current;
      const inputElement = selectBox.querySelector("input");
      if (selectBox === null) return;
      const reportTable = document
        .querySelector("#report-table")
        .getBoundingClientRect();
      const click = event.target.getBoundingClientRect();
      const leftPosition = click.left - reportTable.left + click.width + 1;
      const topPosition = click.top - reportTable.top + click.height + 1;
      const tableValue =
        value?.monthAttendList[day] === 10 ? "" : value?.monthAttendList[day];
      selectBox.style.left = leftPosition + "px";
      selectBox.style.top = topPosition + "px";
      tableRef.current.day = day + 1;
      tableRef.current.site = value?.user_site_seq;
      tableRef.current.monDay = value?.man_day;
      inputElement.value = !tableValue ? "" : tableValue;
    },
    [openedSubmitPayment, isReport]
  );

  // inputRef 커서 포인터
  useEffect(() => {
    if (openedSubmitPayment) {
      inputRef.current.focus();
    }
  }, [openedSubmitPayment]);

  const onSubmitPayment = () => {
    const tableDay = tableRef.current.day;
    const tableSite = tableRef.current.site;
    const tableMonDay = tableRef.current.monDay;
    const inputElement = tableRef.current.querySelector("input");
    const tableValue = inputElement.value;
    if (tableValue === "") {
      alert("값을 입력해 주세요");
      inputElement.focus();
      return;
    }
    if (tableValue > 2) {
      alert("2이하만 수정이 가능합니다");
      inputElement.focus();
      return;
    }

    const items = {
      site_seq: outsourcingState?.site_seq,
      user_site_seq: tableSite,
      work_dt:
        selectedDate.substring(0, 8) + tableDay.toString().padStart(2, 0),
      man_day: Number(inputElement.value),
    };

    modifyCommuteRecordMutate(items);
  };

  return (
    <>
      <Info>
        <CalendarContainer style={{ paddingLeft: "16px" }}>
          <MonthCalendar
            onChangeDate={(date) => {
              setSelectedDate(moment(date).format("YYYY-MM-01"));
            }}
          />
        </CalendarContainer>
      </Info>
      {loading ? (
        <Loading />
      ) : (
        <Board>
          <Panel>
            <div>
              <ButtonGroup>
                <ApplyButton
                  apply={isReport?.is_editable}
                  style={{
                    height: "39px",
                    padding: "0 7px",
                  }}
                  onClick={handlePaymentApply}
                >
                  <Icon src="/assets/icons/plus.svg" />
                  기성 지급 신청
                </ApplyButton>
                <Button onClick={handleHistoryChange}>
                  <Icon src="/assets/icons/log-out-02.svg" />
                  협력사 목록
                </Button>
              </ButtonGroup>
            </div>
          </Panel>
          <BoardHeader>
            <div>
              <em>회사명</em>
              <span>{outsourcingState?.cmpny_nm}</span>
            </div>
            <div>
              <em>계약금액</em>
              <span>
                {outsourcingState?.contract_type === "G"
                  ? Number(outsourcingState?.contract_amt)?.toLocaleString() +
                    "원"
                  : "단가계약"}
              </span>
            </div>
            <div>
              <em>기성 누계</em>
              {/*({!isNaN((Number(outsourcingState?.pay_amt) / Number(outsourcingState?.contract_amt)) * 100) && ((Number(outsourcingState?.pay_amt) / Number(outsourcingState?.contract_amt)) * 100).toFixed(1) > 0 ? ((Number(outsourcingState?.pay_amt) / Number(outsourcingState?.contract_amt)) * 100).toFixed(1) : 0}%)*/}
              <span>
                {Number(outsourcingState?.pay_amt)?.toLocaleString()}원{" "}
                {Number(outsourcingState?.contract_amt) === 0 ||
                outsourcingState?.contract_type !== "G"
                  ? ""
                  : `(${
                      !isNaN(
                        (Number(outsourcingState?.pay_amt) /
                          Number(outsourcingState?.contract_amt)) *
                          100
                      ) &&
                      (
                        (Number(outsourcingState?.pay_amt) /
                          Number(outsourcingState?.contract_amt)) *
                        100
                      ).toFixed(1) > 0
                        ? (
                            (Number(outsourcingState?.pay_amt) /
                              Number(outsourcingState?.contract_amt)) *
                            100
                          ).toFixed(1)
                        : 0
                    }%)`}
              </span>
            </div>
            <div>
              <em>기성 잔액</em>
              <span>
                {(
                  Number(outsourcingState?.contract_amt) -
                  Number(outsourcingState?.pay_amt)
                ).toLocaleString()}
                원
              </span>
            </div>
          </BoardHeader>
          <BoardWrapper>
            <Tabs>
              <div
                className={`${tabsIndex === 0 && "action"}`}
                onClick={() => setTabsIndex(0)}
              >
                인건비 내역
              </div>
              <div
                className={`${tabsIndex === 1 && "action"}`}
                onClick={() => setTabsIndex(1)}
              >
                계산서/명세서 내역
              </div>
            </Tabs>
            {tabsIndex === 0 ? (
              // 인건비 내력
              <>
                {isReport?.attendList &&
                isReport?.attendList[0].user_site_seq !== 0 ? (
                  <>
                    <FirstTab>
                      <Period>
                        <h5>
                          <strong style={{ marginRight: "2px" }}>기간 </strong>
                          <span>{isReport?.period}</span>
                        </h5>
                      </Period>
                      <span>
                        {" "}
                        {isReport?.is_editable === "N" ||
                          "* 근로자의 출역일 선택하면 공수를 등록/수정할 수 있습니다."}
                      </span>
                    </FirstTab>
                    <TableContainer style={{ position: "relative" }}>
                      <SelectWrap
                        className={`${openedSubmitPayment && " action"}`}
                        ref={tableRef}
                      >
                        <InputBox fulled>
                          <input
                            name="selectNum"
                            type="text"
                            ref={inputRef}
                            style={{
                              margin: 0,
                              height: "30px",
                              padding: "8px",
                              fontSize: "14px",
                            }}
                            onChange={(e) => {
                              numberOnly(e);
                            }}
                            maxLength={4}
                            max={2}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                onSubmitPayment();
                              }
                            }}
                          />
                        </InputBox>
                        <ButtonComponent
                          onClick={(event) => {
                            event.stopPropagation();
                            onSubmitPayment();
                          }}
                        >
                          확인
                        </ButtonComponent>
                      </SelectWrap>

                      <TableWrapper>
                        <Table id={"report-table"}>
                          <thead>
                            <tr>
                              <th style={{ minWidth: "30px" }} rowSpan={2}>
                                구분
                              </th>
                              <th style={{ minWidth: "80px" }} rowSpan={3}>
                                이름
                              </th>
                              <th rowSpan={2}>주소</th>
                              <th colSpan={16}>출역사항</th>
                              <th colSpan={3} rowSpan={2}>
                                주차/년월차
                                <br />
                                /추가
                              </th>
                              <th
                                className="th_width"
                                style={{ width: "60px" }}
                                rowSpan={2}
                              >
                                단가
                              </th>
                              <th className="th_width" rowSpan={2}>
                                소득세
                              </th>
                              <th className="th_width" rowSpan={2}>
                                고용보험
                              </th>
                              <th className="th_width" rowSpan={2}>
                                국민연금
                              </th>
                              <th className="th_width" rowSpan={2}>
                                기타/노조
                              </th>
                              <th className="th_width" rowSpan={2}>
                                식대
                              </th>
                            </tr>
                            <tr>
                              <th className="tb_width">1</th>
                              <th className="tb_width">2</th>
                              <th className="tb_width">3</th>
                              <th className="tb_width">4</th>
                              <th className="tb_width">5</th>
                              <th className="tb_width">6</th>
                              <th className="tb_width">7</th>
                              <th className="tb_width">8</th>
                              <th className="tb_width">9</th>
                              <th className="tb_width">10</th>
                              <th className="tb_width">11</th>
                              <th className="tb_width">12</th>
                              <th className="tb_width">13</th>
                              <th className="tb_width">14</th>
                              <th className="tb_width">15</th>
                              <th className="border tb_width">16</th>
                            </tr>
                            <tr>
                              <th>직종</th>
                              <th>주민등록번호/외국인관리번호</th>
                              <th className="tb_width">17</th>
                              <th className="tb_width">18</th>
                              <th className="tb_width">19</th>
                              <th className="tb_width">20</th>
                              <th className="tb_width">21</th>
                              <th className="tb_width">22</th>
                              <th className="tb_width">23</th>
                              <th className="tb_width">24</th>
                              <th className="tb_width">25</th>
                              <th className="tb_width">26</th>
                              <th className="tb_width">27</th>
                              <th className="tb_width">28</th>
                              <th className="tb_width">29</th>
                              <th className="tb_width">30</th>
                              <th className="tb_width">31</th>
                              <th className="tb_width"></th>
                              <th colSpan={3}>총일수/공수</th>
                              <th className="th_width">노무비총액</th>
                              <th className="th_width">지방소득세</th>
                              <th className="th_width">건강보험</th>
                              <th className="th_width">요양보험</th>
                              <th className="th_width">공제계</th>
                              <th className="th_width">실지급액</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isReport?.attendList?.map((e, i) => {
                              if (i === isReport?.attendList.length - 1) {
                                return (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <th colSpan={19} rowSpan={2}>
                                        총계
                                      </th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th className={"table-right"}>
                                        {e.day_amt.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax1.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax2.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax3.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax4.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax5.toLocaleString()}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>{e.attend_cnt}</th>
                                      <th colSpan={2}>{e.man_day}</th>
                                      <th className={"table-right"}>
                                        {e.salary_amt.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax6.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax7.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax8.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.tax9.toLocaleString()}
                                      </th>
                                      <th className={"table-right"}>
                                        {e.real_salary_amt.toLocaleString()}
                                      </th>
                                    </tr>
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td
                                        rowSpan={1}
                                        style={{ minWidth: "30px" }}
                                      >
                                        {e.seq}
                                      </td>
                                      <td
                                        rowSpan={2}
                                        style={{
                                          cursor: "pointer",
                                          minWidth: "80px",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {e.user_nm}
                                      </td>
                                      <td
                                        rowSpan={1}
                                        style={{
                                          maxWidth: "200px",
                                          minWidth: "200px",
                                          textAlign: "left",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                          wordBreak: "break-all",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {e.addr}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "01" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 0)
                                        }
                                      >
                                        {e.monthAttendList[0] === 10
                                          ? ""
                                          : e.monthAttendList[0]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "02" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 1)
                                        }
                                      >
                                        {e.monthAttendList[1] === 10
                                          ? ""
                                          : e.monthAttendList[1]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "03" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 2)
                                        }
                                      >
                                        {e.monthAttendList[2] === 10
                                          ? ""
                                          : e.monthAttendList[2]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "04" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 3)
                                        }
                                      >
                                        {e.monthAttendList[3] === 10
                                          ? ""
                                          : e.monthAttendList[3]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "05" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 4)
                                        }
                                      >
                                        {e.monthAttendList[4] === 10
                                          ? ""
                                          : e.monthAttendList[4]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "06" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 5)
                                        }
                                      >
                                        {e.monthAttendList[5] === 10
                                          ? ""
                                          : e.monthAttendList[5]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "07" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 6)
                                        }
                                      >
                                        {e.monthAttendList[6] === 10
                                          ? ""
                                          : e.monthAttendList[6]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "08" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 7)
                                        }
                                      >
                                        {e.monthAttendList[7] === 10
                                          ? ""
                                          : e.monthAttendList[7]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "09" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 8)
                                        }
                                      >
                                        {e.monthAttendList[8] === 10
                                          ? ""
                                          : e.monthAttendList[8]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "10" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 9)
                                        }
                                      >
                                        {e.monthAttendList[9] === 10
                                          ? ""
                                          : e.monthAttendList[9]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "11" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 10)
                                        }
                                      >
                                        {e.monthAttendList[10] === 10
                                          ? ""
                                          : e.monthAttendList[10]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "12" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 11)
                                        }
                                      >
                                        {e.monthAttendList[11] === 10
                                          ? ""
                                          : e.monthAttendList[11]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "13" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 12)
                                        }
                                      >
                                        {e.monthAttendList[12] === 10
                                          ? ""
                                          : e.monthAttendList[12]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "14" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 13)
                                        }
                                      >
                                        {e.monthAttendList[13] === 10
                                          ? ""
                                          : e.monthAttendList[13]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "15" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 14)
                                        }
                                      >
                                        {e.monthAttendList[14] === 10
                                          ? ""
                                          : e.monthAttendList[14]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "16" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 15)
                                        }
                                      >
                                        {e.monthAttendList[15] === 10
                                          ? ""
                                          : e.monthAttendList[15]}
                                      </td>
                                      {/* 주차/년월차/추가 */}
                                      <td></td>
                                      <td className="tb_width"></td>
                                      <td className="tb_width"></td>

                                      <td className={"table-right"}>
                                        {e.day_amt.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax1.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax2.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax3.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax4.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax5.toLocaleString()}
                                      </td>
                                    </tr>
                                    <tr style={{ position: "relative" }}>
                                      <td>{e.job_position}</td>
                                      <td style={{ textAlign: "left" }}>
                                        {e.id_num}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "17" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 16)
                                        }
                                      >
                                        {e.monthAttendList[16] === 10
                                          ? ""
                                          : e.monthAttendList[16]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "18" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 17)
                                        }
                                      >
                                        {e.monthAttendList[17] === 10
                                          ? ""
                                          : e.monthAttendList[17]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "19" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 18)
                                        }
                                      >
                                        {e.monthAttendList[18] === 10
                                          ? ""
                                          : e.monthAttendList[18]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "20" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 19)
                                        }
                                      >
                                        {e.monthAttendList[19] === 10
                                          ? ""
                                          : e.monthAttendList[19]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "21" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 20)
                                        }
                                      >
                                        {e.monthAttendList[20] === 10
                                          ? ""
                                          : e.monthAttendList[20]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "22" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 21)
                                        }
                                      >
                                        {e.monthAttendList[21] === 10
                                          ? ""
                                          : e.monthAttendList[21]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "23" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 22)
                                        }
                                      >
                                        {e.monthAttendList[22] === 10
                                          ? ""
                                          : e.monthAttendList[22]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "24" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 23)
                                        }
                                      >
                                        {e.monthAttendList[23] === 10
                                          ? ""
                                          : e.monthAttendList[23]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "25" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 24)
                                        }
                                      >
                                        {e.monthAttendList[24] === 10
                                          ? ""
                                          : e.monthAttendList[24]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "26" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 25)
                                        }
                                      >
                                        {e.monthAttendList[25] === 10
                                          ? ""
                                          : e.monthAttendList[25]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "27" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 26)
                                        }
                                      >
                                        {e.monthAttendList[26] === 10
                                          ? ""
                                          : e.monthAttendList[26]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "28" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 27)
                                        }
                                      >
                                        {e.monthAttendList[27] === 10
                                          ? ""
                                          : e.monthAttendList[27]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "29" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 28)
                                        }
                                      >
                                        {e.monthAttendList[28] === 10
                                          ? ""
                                          : e.monthAttendList[28]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "30" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 29)
                                        }
                                      >
                                        {e.monthAttendList[29] === 10
                                          ? ""
                                          : e.monthAttendList[29]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "31" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleOpenComponent(event, e, 30)
                                        }
                                      >
                                        {e.monthAttendList[30] === 10
                                          ? ""
                                          : e.monthAttendList[30]}
                                      </td>
                                      <td
                                        className={`pointer ${
                                          selectedDate.substring(0, 8) + "31" >
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "grayColor"
                                            : ""
                                        }`}
                                      ></td>
                                      <td className="tb_width">
                                        {e.attend_cnt}
                                      </td>
                                      <td className="tb_width" colSpan={2}>
                                        {e.man_day}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.salary_amt.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax6.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax7.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax8.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.tax9.toLocaleString()}
                                      </td>
                                      <td className={"table-right"}>
                                        {e.real_salary_amt.toLocaleString()}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </TableWrapper>
                    </TableContainer>{" "}
                  </>
                ) : (
                  <EmptyContainer
                    selected={false}
                    falseText={"인건비 내역이"}
                    style={{ height: "500px" }}
                  ></EmptyContainer>
                )}
              </>
            ) : getSubPartnerPaymentData &&
              getSubPartnerPaymentData.length > 0 ? (
              <TableContainer>
                <TableWrapper>
                  <Table id={"bill-table"}>
                    <thead>
                      <tr>
                        <th width={"100px"}>기성 지급 신청일</th>
                        <th width={"140px"}>기성 지급 금액 (공급가액)</th>
                        <th width={"320px"}>기성 내역</th>
                        <th width={"320px"}>상세 내용</th>
                        <th width={"80px"}>증빙</th>
                        <th width={"80px"}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {getSubPartnerPaymentData &&
                        getSubPartnerPaymentData?.map((e, i) => (
                          <tr key={i} style={{ height: "31px" }}>
                            <td>
                              {moment.utc(e.pay_dt).format("YYYY년 M월 D일")}
                            </td>
                            <td className={"table-right"}>
                              {Number(e.pay_amt).toLocaleString()}
                            </td>
                            <td className={"table-left"}>{e.pay_acct}</td>
                            <td
                              className={"table-left pointer"}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-place={"left-start"}
                              data-tooltip-html={`${
                                createMarkup(e.pay_desc).__html
                              }`}
                            >
                              {" "}
                              {e.pay_desc}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {e.images !== null ? (
                                <ButtonComponent
                                  style={{ margin: "auto", padding: "0 7px" }}
                                  onClick={(event) => {
                                    setOpenedImages(true);
                                    setSelectedImages(e.images);
                                  }}
                                >
                                  증빙 보기
                                </ButtonComponent>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                {e.is_editable === "Y" && (
                                  <>
                                    <ButtonComponent
                                      style={{ background: "#ff3838" }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        if (
                                          !window.confirm(
                                            "정말로 삭제하시겠습니까?"
                                          )
                                        )
                                          return;
                                        handleDeleteHistory(e);
                                      }}
                                    >
                                      삭제
                                    </ButtonComponent>{" "}
                                    <ButtonComponent
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOpenedFormCreate(false); // 수정
                                        setOpenedPaymentApply(true);

                                        setSelectedReport(e);
                                        setValue("pay_dt", e.pay_dt);
                                        setValue(
                                          "pay_amt",
                                          addCommas(Number(e.pay_amt))
                                        );
                                        setValue("pay_acct", e.pay_acct);
                                        setValue("pay_desc", e.pay_desc);

                                        if (e.images === null) {
                                          setPayImages([]);
                                          return;
                                        }

                                        e?.images?.map((e) => {
                                          delete e.img_seq;
                                        });
                                        setPayImages(e.images);
                                      }}
                                    >
                                      수정
                                    </ButtonComponent>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              </TableContainer>
            ) : (
              <EmptyContainer
                selected={false}
                falseText={"계산서/명세서 내역이"}
                style={{ height: "500px" }}
              ></EmptyContainer>
            )}
          </BoardWrapper>
        </Board>
      )}

      <Tooltip
        id="my-tooltip"
        clickable
        style={{
          width: "300px",
          boxSizing: "border-box",
          backgroundColor: "#EDF2F7",
          color: "#000",
        }}
        opacity={"0.9"}
        place={"right"}
      />
      {/*  증빙 이미지  */}
      <Modal
        opend={openedImages}
        closeModal={() => setOpenedImages(false)}
        header={`증빙 이미지`}
        widthCheck={"45%"}
        okText={"닫기"}
        cancelText={""}
        cancelOnly={true}
      >
        {
          <SelectedImage>
            {selectedImages?.length > 0 &&
              selectedImages?.map((item, i) => (
                <img alt={"증빙 이미지"} key={i} src={item?.pay_img} />
              ))}
          </SelectedImage>
        }
      </Modal>
      {/*  기성 지급 신청  */}
      <Modal
        opend={openedPaymentApply}
        closeModal={() => setOpenedPaymentApply(false)}
        header={`기성 지급 ${openedFormCreate ? "신청" : "수정"}`}
        widthCheck={"40%"}
        cancelText={"취소"}
        okText={"등록하기"}
        formRef={formRef}
      >
        {
          <FieldGroup ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="cmpny_nm">
              <h6>업체명/개인명</h6>
              <span>{outsourcingState?.cmpny_nm}</span>
            </div>
            <InputBox fulled>
              <h6 style={{ marginBottom: "10px" }}>
                기성 지급 신청일 <span>*</span>
              </h6>
              <DayCalendarPicker
                style={{ width: "100%", fontSize: "16px" }}
                {...register("pay_dt", {
                  validate: {
                    register: (value) =>
                      value !== "" || "기성 지급 신청일을 입력해 주세요",
                  },
                })}
                max={moment.utc(new Date()).format("YYYY-MM-DD")}
                change={(newValue) => {
                  setValue("pay_dt", newValue);
                }}
                isValue={getValues("pay_dt") || ""}
              />
            </InputBox>
            <InputBox fulled>
              <h6>
                기성 지급 금액 (공급가액) <span>*</span>
              </h6>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                  columnGap: "7px",
                }}
              >
                <input
                  name="pay_amt"
                  type="text"
                  defaultValue={0}
                  {...register("pay_amt", {
                    required: "기성 지급 금액을 입력해 주세요",
                  })}
                  onChange={(e) => {
                    numberOnly(e);
                    e.target.value = addCommas(parseInt(e.target.value));
                  }}
                />
                원
              </div>
            </InputBox>
            <InputBox fulled>
              <h6>
                기성 내역 <span>*</span>
              </h6>
              <input
                name="pay_acct"
                type="text"
                {...register("pay_acct", {
                  validate: {
                    register: (value) =>
                      value !== "" || "기성 내역을 입력해 주세요",
                    noSpaces: (value) =>
                      value.trim() !== "" || "기성 내역을 입력해 주세요",
                  },
                })}
              />
            </InputBox>
            <InputBox fulled>
              <h6>
                상세 내용 <span>*</span>
              </h6>
              <textarea
                style={{ height: "200px" }}
                name="pay_desc"
                {...register("pay_desc", {
                  validate: {
                    register: (value) =>
                      value !== "" || "상세 내용을 입력해 주세요",
                    noSpaces: (value) =>
                      value.trim() !== "" || "상세 내용을 입력해 주세요",
                  },
                })}
              ></textarea>
            </InputBox>

            <Fields imgBox={"true"}>
              <Uploader>
                <h6>증빙 이미지</h6>
                <div>
                  {payImages?.length > 0 &&
                    payImages?.map((e, i) => (
                      <ImageBox key={e.pay_img}>
                        <ImageUploader
                          onFileAdded={() => ""}
                          onFileRemoved={() => handleDeleteFile(e.pay_img)}
                          deleteIcon={
                            <ImageDelete className="delete-label">
                              <Icon src="/assets/icons/trash-04.svg" />
                            </ImageDelete>
                          }
                        />
                        <ImageDefault
                          src={e?.pay_img}
                          style={{ width: "inherit" }}
                        />
                      </ImageBox>
                    ))}
                  {payImages?.length < 5 && (
                    <ImageBox>
                      <ImageUploader
                        onFileAdded={(e) => handleUploadFile("pay_img", e)}
                        uploadIcon={
                          <ImagePreview>
                            <img
                              src="/assets/icons/image-03.svg"
                              alt={"upload"}
                            />
                            <ImageButton>이미지 업로드</ImageButton>
                          </ImagePreview>
                        }
                      />
                    </ImageBox>
                  )}
                </div>
              </Uploader>
            </Fields>
          </FieldGroup>
        }
      </Modal>
    </>
  );
};

const ImageBox = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px dashed #cbd5e0;
  overflow: hidden;

  > * {
    position: absolute;
  }

  //.uploader__file,
  //.delete-label,
  //.upload-label {
  //    display: none;
  //}

  .uploader__container {
    width: 100%;
    height: 100%;
  }

  #file_uploader svg,
  .uploader__btn svg {
    display: none;
  }
`;
const ImagePreview = styled.div`
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  justify-content: center;
  flex: 1 0 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  align-self: stretch;
  position: relative;
  background: #fff;
  //margin: 0 12px;
  cursor: pointer;

  img {
    top: 50px;
    position: absolute;
  }

  div {
    position: absolute;
    bottom: 50px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 15px;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
`;

const ImageDelete = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;

  img {
    border-radius: 50%;
    width: 20px;
    padding: 4px;
    border: 1px solid #cbd5e0;
    background: #fff;
    height: 20px;
  }
`;

const ImageButton = styled.div`
  color: #2d3748;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #cbd5e0;
  background: #fff;
  cursor: pointer;
  z-index: 999;
`;

const Uploader = styled.div`
  cursor: pointer;

  .hover-wrapper {
    width: 100%;
    height: 100%;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 4px;

    span {
      color: #ff3838;
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
`;

const ImageDefault = styled.img`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
`;

const Fields = styled.div`
  flex-wrap: wrap;
  // width: 500px;
  ${({ imgBox }) =>
    imgBox === "true" ? "display:grid; column-gap: 20px;" : "display:flex"};
  // grid-template-columns: repeat(2, 1fr);

  > div {
  }

  @media only screen and (min-width: 992px) {
    > div {
      //width: 33.3%;
    }
  }

  .uploader__file_input_label {
  }

  .uploader__file_input_label > div {
    position: absolute;
  }
`;

const FieldGroup = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;

  textarea,
  input {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }

  input:read-only {
    background-color: #f7f7f7;
  }

  em {
    font-size: 12px;
    font-weight: bold;
    color: #718096;
    margin-left: 3px;
  }

  .cmpny_nm {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    figure {
      margin: 0;
      display: flex;
      align-items: center;
      min-height: 34px;
    }

    h6 {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      width: 120px;
      margin: 0;
    }

    span {
      color: #171923;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  label {
    margin-left: 0;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 15px;
    }
  }
`;
const SelectWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 9px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;

  align-items: center;
  column-gap: 7px;
  z-index: 9999;
  border: 1px solid #cbd5e0;
  display: none;
  width: 150px;

  &.action {
    display: flex;
  }

  input {
    border-radius: 6px;
  }

  button {
    white-space: nowrap;
    height: 30px;
    padding: 0 10px;
  }
`;
const Form = styled.form`
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const SelectedImage = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  img {
    width: 100%;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  margin-top: 18px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;

  #report-table {
  }

  button span {
    font-size: 12px;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 2px;
    font-size: 11px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
  }

  th {
    background-color: #e2e8f0;
    white-space: nowrap;
    text-align: center;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td {
    //  min-height: 22px;
    // box-sizing: border-box;
    background: #fff;
    text-align: center;

    overflow-wrap: break-word;
    //word-break: break-all;
    white-space: normal;
  }

  thead {
    height: 31px;
  }

  .grayColor {
    background-color: #ccc;
  }

  .selectDay {
    background-color: #fef6d5;
  }

  .tb_width {
    width: 23px;
    min-width: 23px;
  }

  .th_width {
    width: 60px;
  }

  @media only screen and (max-width: 992px) {
    &&#bill-table {
      table-layout: fixed;
    }
  }
`;
const ApplyButton = styled(ButtonComponent)`
  background-color: ${(props) => props.apply === "N" && "#d2d3d4"};
  color: ${(props) => props.apply === "N" && "#fff"};
`;

const FirstTab = styled.div`
  > span {
    color: #ff3838;
    font-weight: 400;
    font-size: 12px;
    margin-top: -3px;
    display: block;
  }
`;
const Period = styled.div`
  h5 {
    margin: 20px 0 3px;
  }
`;
const BoardWrapper = styled.div`
  // margin-bottom: 25px;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: 38px;
`;
const Tabs = styled.div`
  width: 700px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #edf2f7;
  padding: 4px;
  height: 40px;
  box-sizing: border-box;
  margin: 0 auto;

  @media only screen and (max-width: 992px) {
    //background-color: red;
    width: 95%;
  }

  > div {
    width: 50%;
    line-height: 22px;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;

    &.action {
      background-color: #fff;
    }
  }
`;
const BoardHeader = styled.div`
  padding: 0 32px;
  gap: 20px 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  max-width: 800px;

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    //width: 350px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  em {
    color: #718096;
    display: block;
    width: 100px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }

  span {
    width: calc(100% - 100px);
    text-align: right;
    color: rgba(0, 0, 0, 0.92);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
`;
const Info = styled.div`
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    margin-top: 0;
    height: fit-content;
  }

  @media only screen and (max-width: 992px) {
    > div {
      margin-left: 0;
      width: 100%;
    }
  }
`;
const Icon = styled.img`
  width: 16px;
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: 37px;
  height: 37px;
  gap: 9px;
  border: 1.125px solid #e2e8f0;
  border-radius: 6px;
  background: #fff;
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;

  leading-trim: both;
  text-edge: cap;
  // margin-top: 5px;
`;

const Panel = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 32px;
`;
const Board = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  margin-top: 24px;
  padding: 28px 0;
  box-sizing: border-box;
  min-height: 729px;
  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  padding: 20px 24px 32px 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 768px) {
    //width: 30%;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
  }
`;

export default OutsourcingHistory;
