import React from "react";
import styled from "@emotion/styled";
//import {Select} from '@mui/material';
import ReactSelect, { components } from "react-select";

const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];
const selectStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    marginTop: "0px",
    minHeight: "34px", // 원하는 높이로 변경
    height: "100%",
    "&:hover": {
      border: "1px solid #CBD5E0",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "100%", // 원하는 높이로 변경
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
    backgroundColor: "transparent",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "100%", // 원하는 높이로 변경
  }),
  placeholder: (provided) => ({
    ...provided,
    " > div": {
      display: "flex",
      alignItems: "center",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    height: "34px",
  }),
};
const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-size: 16px;
  //font-size: 16px;
  font-style: normal;
  box-sizing: border-box;
  font-weight: 500;
  background: #fff;
  width: 125px;
  height: 38px;
  // border: 1px solid #CBD5E0;
  //margin-top: 5px;
  //border-color: #CBD5E0;

  .react-select__input {
    font-size: 16px;
  }

  .react-select__control {
    height: 38px !important;
  }

  > div {
    cursor: pointer;
    color: #171923;
    border: 1px solid #cbd5e0;
    //border: 1px solid #CBD5E0;#E2E8F0
  }

  .react-select-placeholder {
    //content: none;
  }
`;
const SingleValueContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PagesList = ({ onChange, children, ...props }) => {
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <SingleValueContainer>
          <Icon
            src={`/assets/icons/menu-02.svg`}
            style={{ marginRight: "8px" }}
          />
          {children}
        </SingleValueContainer>
      </components.SingleValue>
    );
  };
  const customComponents = {
    Input: (props) => <components.Input {...props} readOnly />, // readOnly 속성 추가,
    SingleValue,
  };
  return (
    <PagesListWrapper style={props.style}>
      <span>페이지당 목록 수</span>
      <Select
        styles={selectStyles}
        components={customComponents}
        placeholder={
          <div>
            <Icon
              style={{ marginRight: "6px" }}
              src={`/assets/icons/menu-02.svg`}
            />
          </div>
        }
        options={options}
        //value={value === null || Object.keys(value).length === 0 ? {value: '', label: placeholder} : value}
        onChange={onChange}
        defaultValue={options[0]}
      ></Select>
    </PagesListWrapper>
  );
};
const PagesListWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 25px;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;
const Icon = styled.img`
  width: 16px;
`;
export default PagesList;
