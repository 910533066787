import React, { useRef, useState } from "react";
import AxiosNavigate from "../../library/axios-navigate";
import { Header } from "../../compontents/Header";
import MonthCalendar from "../../compontents/MonthCalendar";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import ButtonComponent from "../../compontents/Button";
import { useMutation } from "@tanstack/react-query";
import { downloadRepost } from "../../library/axios-query";
import moment from "moment";
import { downloadFile, parseBlobError } from "../../bin/common";
import { Info } from "../../library/styled-components.js";
const LaborDownloadReport = () => {
  const belongRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-01")
  );

  const { mutate: downloadRepostItem } = useMutation({
    mutationFn: downloadRepost,
    mutationKey: ["downloadRepost"],
    onSuccess: (data) => {
      downloadFile(
        data,
        `${moment.utc(selectedDate).format("YYYY년_M월")}_4대보험_신고.zip`
      ); // 원하는 파일 이름으로 설정하세요
    },
    onError: async (error) => {
      if (error.response && error.response.data) {
        const errorData = await parseBlobError(error);
        alert(errorData.message);
      }
    },
  });

  return (
    <AxiosNavigate>
      <div>
        <Header>신고자료</Header>
        <Content>
          <Info>
            <CalendarContainer style={{ paddingLeft: "16px" }}>
              <MonthCalendar
                onChangeDate={(date) => {
                  setSelectedDate(dayjs(date).format("YYYY-MM-01"));
                  if (belongRef.current) {
                    belongRef.current.clearValue();
                  }
                }}
              />
            </CalendarContainer>
          </Info>
          <Board>
            <ButtonComponent
              style={{
                padding: "0 7px",
                height: "32px",
                //  margin: 'auto',
                lineHeight: "32px",
                fontWeight: "300",
              }}
              onClick={(event) => {
                event.preventDefault();
                downloadRepostItem(selectedDate);
              }}
            >
              {"신고자료 다운로드"}
            </ButtonComponent>
          </Board>
        </Content>
      </div>
    </AxiosNavigate>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 718px;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
const Icon = styled.img`
  width: 16px;
`;

const Board = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  margin-top: 24px;
  padding: 20px;
  box-sizing: border-box;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;
const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 32px;
`;
const CalendarContainer = styled.div`
  width: 100%;
  padding: 20px 24px 32px 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
  }
`;
export default LaborDownloadReport;
