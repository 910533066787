import React, { useRef, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const MonthCalendarPicker = ({ change = () => {}, isValue, style = {} }) => {
  const datePickerInput = useRef(null);

  const newTheme = (customStyles) =>
    createTheme({
      //   ...theme,
      components: {
        Mui: {
          styleOverrides: {
            "&.Mui-focused": {
              borderColor: "#cbd5e0",
              //   borderWidth: "1px !important",
            },
            "&.Mui-selected": {
              backgroundColor: "rgb(50, 121, 245)",
            },
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.2)",
            },
          },
        },

        MuiPopper: {
          styleOverrides: {
            root: {
              zIndex: "9999 !important",
            },
          },
        },
        MuiStack: {
          styleOverrides: {
            root: {
              paddingTop: "0px",
              overflow: "unset !important",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              height: "39px",
            },
            input: {
              padding: "0 15px",
              color: "#171923",
              boxSizing: "border-box",
              fontSize: "16px",
              fontWeight: "500",
            },
            notchedOutline: {
              borderColor: "#cbd5e0 !important",
              borderWidth: "1px !important",
            },
            // },
          },
        },

        MuiFormControl: {
          styleOverrides: {
            root: {
              height: "39px",
              width: customStyles.width || "230px",
            },
          },
        },
        MuiDateCalendar: {
          styleOverrides: {
            root: {
              maxHeight: "285px",
              height: "285px",
              color: "#000",
              borderRadius: "2px",
              borderWidth: "1px",
              borderColor: "#cbd5e0",
              border: "1px solid",
            },
          },
        },
      },
    });
  useEffect(() => {
    if (!datePickerInput) return;
    datePickerInput.current.disabled = true;
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateFormats={{
        monthShort: `M월`,
        yearShort: "YYYY년",
        year: "YYYY년",
        month: "M월",
      }}
    >
      <DemoContainer components={["DatePicker"]}>
        <ThemeProvider theme={newTheme(style)}>
          <DatePicker
            views={["year", "month"]}
            yearsPerRow={3}
            openTo="month"
            type="date"
            format={"YYYY년 M월"}
            maxDate={dayjs(new Date().getFullYear() + "12-31")}
            minDate={dayjs("2020-01-01")}
            onMonthChange={(newValue) => {
              change(dayjs(newValue).format("YYYY-MM"));
            }}
            value={isValue ? dayjs(isValue) : null}
            inputRef={datePickerInput}
          />
        </ThemeProvider>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default MonthCalendarPicker;
