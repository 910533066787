import React, { useRef, useEffect, forwardRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DayCalendarPicker = forwardRef((porps, ref) => {
  const {
    change = () => {},
    isValue,
    min = null,
    max = null,
    style = {},
  } = porps;

  const datePickerInput = useRef(null);

  const newTheme = (customStyles) =>
    createTheme({
      //   ...theme,
      components: {
        Mui: {
          styleOverrides: {
            "&.Mui-focused": {
              borderColor: "#cbd5e0",
              //   borderWidth: "1px !important",
            },
            "&.Mui-selected": {
              backgroundColor: "rgb(50, 121, 245)",
            },
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.2)",
              backgroundColor: "#fff",
            },
          },
        },

        MuiPopper: {
          styleOverrides: {
            root: {
              zIndex: "9999 !important",
            },
          },
        },
        MuiStack: {
          styleOverrides: {
            root: {
              paddingTop: "0px",
              overflow: "unset !important",
            },
            ".MuiTextField-root": {
              minWidth: (customStyles.width || "200px") + "!important",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              // paddingRight: "11px",
              height: "39px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            },
            input: {
              padding: " 0 0 0 10px !important",
              color: "#000000eb !important",
              boxSizing: "border-box",
              fontSize: (customStyles.fontSize || "14px ") + " !important",
              fontWeight: "500",
              marginBottom: "0px !important",
              marginTop: "0px !important",
              "&:disabled": {
                backgroundColor: "transparent !important",
              },
            },
            notchedOutline: {
              borderColor: "#cbd5e0 !important",
              borderWidth: "1px !important",
            },
            // },
          },
        },

        MuiFormControl: {
          styleOverrides: {
            root: {
              height: customStyles.height || "34px",
              width: customStyles.width || "230px",
              minWidth: (customStyles.width || "200px") + "!important",
            },
          },
        },
        MuiDateCalendar: {
          styleOverrides: {
            root: {
              maxHeight: "350px",
              height: "350px",
              color: "#000",
              borderRadius: "2px",
              borderWidth: "1px",
              borderColor: "#cbd5e0",
              border: "1px solid",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              display: customStyles.icon || "inline-flex",
            },
          },
        },
      },
    });
  useEffect(() => {
    if (!datePickerInput) return;
    datePickerInput.current.disabled = true;
    datePickerInput.current.placeholder = "날짜를 선택해주세요";
  }, []);

  return (
    <LocalizationProvider
      ref={ref}
      dateAdapter={AdapterDayjs}
      dateFormats={{
        monthShort: `M월`,
        yearShort: "YYYY년",
        year: "YYYY년",
        month: "MM월",
        normalDate: "DD일",
      }}
    >
      <DemoContainer components={["DatePicker"]}>
        <ThemeProvider theme={newTheme(style)}>
          <DatePicker
            views={["year", "month", "day"]}
            yearsPerRow={3}
            type="date"
            openTo="day"
            format={"YYYY년 MM월 DD일"}
            maxDate={
              !max ? dayjs(new Date().getFullYear() + 5 + "12-31") : dayjs(max)
            }
            minDate={!min ? dayjs("2020-01-01") : dayjs(min)}
            onChange={(newValue) => {
              change(dayjs(newValue).format("YYYY-MM-DD"));
            }}
            value={isValue ? dayjs(isValue) : null}
            inputRef={datePickerInput}
            placeholder={"날짜를 선택해주새요"}
          />
        </ThemeProvider>
      </DemoContainer>
    </LocalizationProvider>
  );
});

export default DayCalendarPicker;
