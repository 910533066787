import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import SideModal from "../../compontents/SideModal";
import { ModalButton } from "../../compontents/ModalButton";
import axiosInstance from "../../library/axios-index";
import AxiosNavigate from "../../library/axios-navigate";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { BlockScrolling, checkRole } from "../../bin/common";
import { RoleType } from "../../bin/enum";
import SearchBox from "../../compontents/Search";
import { InputBox, Select } from "../../library/styled-components";
import EmptyContainer from "../../compontents/EmptyContainer";
import Loading from "../../compontents/Loading";

const readOnlyYnOption = [
  { value: "Y", label: "예" },
  { value: "N", label: "아니오" },
];

// const selectStyle = {
//     control: base => ({
//         ...base,
//         boxShadow: 'none',
//         '&:hover': {
//             border: '1px solid #E2E8F0',
//         },
//     })
// };

function SettingRole() {
  const location = useLocation();
  const [cookies] = useCookies(["role"]);

  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState([]);
  const [roles, setRoles] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedAuthSeq, setSelectedAuthSeq] = useState([]);
  const [readOnlyYn, setReadOnlyYn] = useState(readOnlyYnOption[0].value);

  const [opendRole, setOpendRole] = useState(false);
  const [opendDetailRole, setOpendDetailRole] = useState(false);

  BlockScrolling([opendDetailRole, opendRole]);
  // BlockScrolling(opendRole);
  const handleRole = useCallback(() => {
    if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
      return;
    }

    setKeywords([]);
    setOpendRole(true);
  }, []);

  const handleDetailRole = useCallback(
    async (auth_seq, readonly_yn) => {
      setSelectedAuthSeq(auth_seq);
      setReadOnlyYn(readonly_yn);

      setKeywords((x) =>
        roles
          .find((e) => e.auth_seq === auth_seq)
          .menus.map((e) => ({
            value: e.menu_seq,
            label: e.menu_nm,
          }))
      );

      setSelectedEmployees(
        (x) => roles.find((e) => e.auth_seq === auth_seq).users
      );

      setOpendDetailRole(true);

      await handleSearchEmploy("");
    },
    [roles, selectedAuthSeq]
  );

  const handleNewRole = useCallback(async () => {
    const formData = Array.from(
      document.querySelectorAll(".new-role-form input")
    ).reduce((acc, cur, i) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {});

    if (formData.auth_nm === "") {
      alert("권한을 입력해 주세요");
      return;
    }

    if (keywords.length === 0) {
      alert("메뉴를 선택해 주세요");
      return;
    }

    const token = localStorage.getItem("admin_token");
    try {
      const {
        data: {
          result: { done },
        },
      } = await axiosInstance.post(`/company/newRole`, {
        lang: "ko",
        uuid: "string",
        token,
        auth_nm: formData.auth_nm,
        readonly_yn: formData.readonly_yn,
        menu_seqs: keywords.map((e) => e.value),
      });

      setOpendRole(false);
      setKeywords([]);

      await loadRoles();
    } catch (e) {
      alert(e.response.data.message);
      return;
    }
  }, [opendRole, keywords]);

  const handleDeleteRole = useCallback(async (auth_seq) => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;

    const token = localStorage.getItem("admin_token");
    try {
      await axiosInstance.post(`/company/deleteRole`, {
        lang: "ko",
        uuid: "string",
        token,
        auth_seq,
      });
      await loadRoles();
    } catch (e) {
      return alert(e.response.data.message);
    }
  }, []);

  const handleModifyDetailMenuRole = useCallback(async () => {
    const token = localStorage.getItem("admin_token");
    const readOnlyYn =
      document.getElementsByName("modify_readonly_yn")[0].value;
    const {
      data: {
        result: { done },
      },
    } = await axiosInstance.post(`/company/modifyDetailMenuRole`, {
      lang: "ko",
      uuid: "string",
      token,
      auth_seq: selectedAuthSeq,
      readonly_yn: readOnlyYn,
      menu_seqs: keywords.map((e) => e.value),
      cu_seqs: selectedEmployees.map((e) => e.cu_seq),
    });

    setOpendDetailRole(false);
    setKeywords([]);
    setSelectedEmployees([]);

    await loadRoles();
  }, [opendDetailRole, keywords, selectedEmployees, selectedAuthSeq]);

  const handleKeyword = useCallback(
    async (event) => {
      if (keywords.find((e) => e.value === event.value)) {
        return;
      }

      setKeywords((e) => [...e, event]);
    },
    [keywords]
  );

  const handleDeleteKeyword = useCallback(
    async (value) => {
      setKeywords((keywords) => keywords.filter((e) => e.value !== value));
    },
    [keywords]
  );

  const loadRoles = async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: { menus, roles, totalCount },
      },
    } = await axiosInstance.post(`/company/listRole`, {
      lang: "ko",
      uuid: "string",
      token,
    });

    setMenus(menus);
    setRoles(roles);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const handleSearchEmploy = useCallback(
    async (value) => {
      const token = localStorage.getItem("admin_token");

      const {
        data: {
          result: { employees, countOfLaborEmploy },
        },
      } = await axiosInstance.post(`/company/searchRoleEmploy`, {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: value,
      });

      setEmployees(employees);
    },
    [employees]
  );

  const handleSelectedEmploy = useCallback(
    async (values) => {
      if (selectedEmployees.find((e) => e.cu_seq === values.cu_seq)) {
        return;
      }

      setSelectedEmployees((e) => [
        ...e,
        {
          ...values,
        },
      ]);
    },
    [selectedEmployees]
  );

  const handleDeleteSelectedEmploy = useCallback(
    async (value) => {
      setSelectedEmployees((employees) =>
        employees.filter((e) => e.cu_seq !== value)
      );
    },
    [selectedEmployees]
  );

  useEffect(() => {
    (async () => {
      await loadRoles();
    })();
  }, []);
  return (
    <AxiosNavigate>
      <div>
        <Panel>
          <div>
            <BoardHeader>
              <h2>권한 설정</h2>
              <h5>
                <img src="/assets/icons/alert-circle.svg" />
                퇴사자는 자동삭제됩니다
              </h5>
            </BoardHeader>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ backgroundColor: "#3279F5", color: "#fff" }}
              onClick={handleRole}
            >
              <Icon src="/assets/icons/plus.svg" /> 권한 추가
            </Button>
          </div>
        </Panel>

        {loading ? (
          <Loading></Loading>
        ) : (
          <TableContainer>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th>권한</th>
                    <th>메뉴</th>
                    <th>임직원</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {roles?.length === 0 ? (
                    <tr style={{ height: "500px" }}>
                      <td colSpan={3}>
                        <EmptyContainer
                          falseText={"등록된 설정이"}
                          style={{ background: "#fff" }}
                        ></EmptyContainer>
                      </td>
                    </tr>
                  ) : (
                    roles.map((e, i) => (
                      <tr key={i}>
                        <td>
                          {e.auth_nm}
                          <br />
                          <div style={{ color: "blue" }}>
                            {e.readonly_yn === "Y" ? "(조회 전용)" : ""}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              gap: "2px",
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                              alignItems: "center",
                              rowGap: "5px",
                            }}
                          >
                            {e.menus.map((e, i) => (
                              <Badge
                                key={i}
                                style={{
                                  justifyContent: "center",
                                  fontWeight: "400",
                                }}
                              >
                                {e.menu_nm}
                              </Badge>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              gap: "3px",
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr 1fr",
                              alignItems: "center",
                            }}
                          >
                            {e.users.map((e, i) => (
                              <div
                                key={i}
                                style={{
                                  marginRight: "3px",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyItems: "start",
                                }}
                              >
                                <Avatar
                                  style={{
                                    marginRight: "3px",
                                    width: "24px",
                                    height: "24px",
                                    backgroundImage: `url(${
                                      e.photo || "/avatar.png"
                                    })`,
                                  }}
                                />
                                {e.user_nm}
                                <Badge>{e.team_nm}</Badge>
                              </div>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              columnGap: "3px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                if (
                                  !checkRole(
                                    location.pathname,
                                    cookies.role,
                                    RoleType.WRITE
                                  )
                                ) {
                                  return;
                                }
                                handleDeleteRole(e.auth_seq);
                              }}
                            >
                              삭제
                            </Button>
                            <Button
                              onClick={() => {
                                if (
                                  !checkRole(
                                    location.pathname,
                                    cookies.role,
                                    RoleType.WRITE
                                  )
                                ) {
                                  return;
                                }
                                handleDetailRole(e.auth_seq, e.readonly_yn);
                              }}
                            >
                              수정
                            </Button>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </TableWrapper>
          </TableContainer>
        )}

        <SideModal
          opend={opendRole}
          closeModal={() => setOpendRole(false)}
          header={"권한 추가"}
        >
          <form className="new-role-form">
            <Card>
              <InputBox fulled>
                <h5>권한</h5>
                <input name="auth_nm" maxLength={25} />
              </InputBox>
              <h5>조회만 가능(읽기 전용)</h5>
              <div>
                <Select
                  name="readonly_yn"
                  // styles={selectStyle}
                  defaultValue={readOnlyYnOption[1]}
                  options={readOnlyYnOption}
                />
              </div>
            </Card>

            <Card>
              <h5>메뉴</h5>
              <div>
                <Select
                  //  styles={selectStyle}
                  placeholder={"메뉴선택"}
                  options={menus.map((e) => ({
                    value: e.menu_seq,
                    label: e.menu_nm,
                  }))}
                  onChange={handleKeyword}
                />
              </div>

              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {keywords.map((e, i) => (
                  <Label
                    key={i}
                    onClick={() => handleDeleteKeyword(e.value)}
                    style={{ cursor: "pointer" }}
                  >
                    {e.label}{" "}
                    <Icon
                      style={{ marginLeft: "2px" }}
                      src="/assets/icons/left-icon.svg"
                    />
                  </Label>
                ))}
              </div>
            </Card>
          </form>

          <ModalButtonGroup>
            <ModalButton onClick={() => setOpendRole(false)}>취소</ModalButton>
            <ModalButton
              style={{ color: "#fff", background: "#3279F5" }}
              onClick={handleNewRole}
            >
              확인
            </ModalButton>
          </ModalButtonGroup>
        </SideModal>

        <SideModal
          opend={opendDetailRole}
          closeModal={() => setOpendDetailRole(false)}
          header={"권한 설정"}
        >
          <form className="modify-role-form">
            <Card>
              <h5>메뉴</h5>

              <div>
                <Select
                  //  styles={selectStyle}
                  placeholder={"메뉴선택"}
                  options={menus.map((e) => ({
                    value: e.menu_seq,
                    label: e.menu_nm,
                  }))}
                  onChange={handleKeyword}
                />
              </div>

              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {keywords.map((e, i) => (
                  <Label
                    key={i}
                    onClick={() => handleDeleteKeyword(e.value)}
                    style={{ cursor: "pointer" }}
                  >
                    {e.label}{" "}
                    <Icon
                      style={{ marginLeft: "2px" }}
                      src="/assets/icons/left-icon.svg"
                    />
                  </Label>
                ))}
              </div>
              <br />
              <h5>조회만 가능(읽기 전용)</h5>
              <div>
                <Select
                  name="modify_readonly_yn"
                  // styles={selectStyle}
                  defaultValue={readOnlyYnOption.find(
                    (e) => e.value === readOnlyYn
                  )}
                  options={readOnlyYnOption}
                />
              </div>
            </Card>

            <Card>
              <h5>임직원</h5>

              <div style={{ marginTop: "8px" }}>
                <SearchBox
                  placeholder={"직원 이름을 검색해주세요"}
                  style={{ backgroundColor: "#fff" }}
                  onKeyDown={(e) =>
                    e.key === "Enter" && handleSearchEmploy(e.target.value)
                  }
                ></SearchBox>

                {employees.length === 0 ? (
                  <EmptyLaborCard>근로자가 존재하지 않습니다</EmptyLaborCard>
                ) : (
                  employees.map((e, i) => (
                    <LaborCard key={i} onClick={() => handleSelectedEmploy(e)}>
                      <div>
                        <Avatar
                          style={{
                            backgroundImage: `url(${e.photo || "/avatar.png"})`,
                          }}
                        />
                      </div>
                      <LaborCardContent>
                        <h5>
                          {e.user_nm} / {e.phone_num}
                        </h5>
                        <h6>
                          {e.team_nm} / {e.position_nm}
                        </h6>
                      </LaborCardContent>
                    </LaborCard>
                  ))
                )}

                <div
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {selectedEmployees.map((e, i) => (
                    <Label
                      key={i}
                      onClick={() => handleDeleteSelectedEmploy(e.cu_seq)}
                      style={{ cursor: "pointer" }}
                    >
                      {e.team_nm} {e.position_nm} / {e.user_nm}
                      <Icon
                        style={{ marginLeft: "2px" }}
                        src="/assets/icons/left-icon.svg"
                      />
                    </Label>
                  ))}
                </div>
              </div>
            </Card>
          </form>

          <ModalButtonGroup>
            <ModalButton onClick={() => setOpendDetailRole(false)}>
              취소
            </ModalButton>
            <ModalButton
              style={{ color: "#fff", background: "#3279F5" }}
              onClick={handleModifyDetailMenuRole}
            >
              확인
            </ModalButton>
          </ModalButtonGroup>
        </SideModal>
      </div>
    </AxiosNavigate>
  );
}

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 25px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 6px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #e2e8f0;
  color: #1a202c;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

// const Select = styled(ReactSelect)`
//     color: #4A5568;
//     leading-trim: both;
//     text-edge: cap;
//     font-size: 14px;
//     border-radius: 6px;
//     background: #FFF;
//     margin-top: 5px;
//
//     div {
//         cursor: pointer;
//         color: #4A5568;
//         border-color: #E2E8F0;
//     }
// `;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 25px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px;
    font-size: 13px;
    font-weight: 300;
    //border-right: 1px solid #CBD5E0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    //background-color: #F7FAFC;
    text-align: center;
    padding: 2px;
  }

  td {
    text-align: center;
  }

  tr:last-child td {
    border-bottom: none;
  }

  thead {
    height: 31px;
  }
`;

const PageGroup = styled.div`
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    padding: 0;
    list-style: none;
  }

  li a {
    display: inline-block;
    cursor: pointer;
    color: #000;
    padding: 6px 12px;
    text-decoration: none;
    font-size: 12px;
  }

  li.active a {
    color: #fff;
    background-color: #3279f5 !important;
    border-radius: 4px;
  }

  li:hover:not(.active) a {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;

const BoardHeader = styled.div`
  margin-bottom: 14px;
  margin-top: 28px;

  h2,
  h5 {
    margin: 0;
  }

  h5 {
    margin-top: 4px;
    display: flex;
    algin-items: center;
  }

  img {
    margin-right: 4px;
  }
`;

const Label = styled.span`
  padding: 2px 4px;
  align-items: center;
  margin-right: 6px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  color: #718096;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const Card = styled.div`
  padding: 20px;
  min-height: 124px;
  margin-bottom: 16px;
  border-radius: 12px;
  background: #f7fafc;

  h5 {
    color: #171923;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
`;

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Input = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 90%;
  border: 0px;
  outline: none;

  padding: 8px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  margin-bottom: 12px;

  :focus {
  }
`;

const EmptyLaborCard = styled.div`
  color: #4a5568;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  //  border: 1px solid #ff3838;
  text-align: center;
  padding-top: 26px;
  padding-bottom: 26px;
`;

const LaborCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0;
  cursor: pointer;

  &:hover {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-left: 8px;
`;

const LaborCardContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  h6 {
    color: #718096;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const Badge = styled.div`
  color: #1a202c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  padding: 2px 4px;
  align-items: center;
  //justify-content: center;
  gap: 4px;
  border-radius: 5px;
  background: #edf2f7;
  margin-left: 3px;
`;

export default SettingRole;
