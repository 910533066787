import React from "react";
import styled from "@emotion/styled";
import ReactModal from "react-modal";
import { ModalButton } from "./ModalButton";
import { useWindowSize } from "react-use";

const customStyles = {
  overlay: {
    zIndex: "9999",
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    // marginTop: '50px',
    // maxWidth: "90%",
    // width: "60%",
    height: "fit-content",
    // maxHeight: '982px',
    maxHeight: "90%",
    borderRadius: "6px",
    padding: 0,
    inset: 0,
  },
};

// handleOk 이 없으면 버튼 타입 type="submit" 적용
function TableModal({
  children,
  opend,
  closeModal,
  header,
  buttons,
  okText,
  footer = null,
  handleOk,
  formRef,
  component,

  widthCheck,
  noCloseModal,
  heightCheck = "auto",
  style,
}) {
  const { width } = useWindowSize();

  return (
    <ReactModal
      isOpen={opend}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: customStyles.overlay,
        content: {
          ...customStyles.content,
          width: "1745px",
          height: "885px",
          boxSizing: "border-box",
          // overflow: "unset",
          ...style,
          ...(width < 992
            ? {
                marginTop: 0,
                width: "100%",
                maxWidth: "100%",
                height: "100%",
                maxHeight: "100%",
              }
            : {}),
        },
      }}
      ariaHideApp={false}
    >
      <Padding>
        <Header>
          {width < 992 ? (
            <>
              <div>
                <SidebarButton
                  onClick={closeModal}
                  alt="bars"
                  src="/assets/icons/chevron-left.svg"
                />
              </div>
              <div>
                {typeof header === "string" ? (
                  <h4 style={{ color: "#171923", fontSize: "18px", margin: 0 }}>
                    {header}
                  </h4>
                ) : (
                  header
                )}
              </div>
              <div></div>
            </>
          ) : (
            <>
              <div>
                {typeof header === "string" ? (
                  <h4 style={{ color: "#171923", fontSize: "18px", margin: 0 }}>
                    {header}
                  </h4>
                ) : (
                  header
                )}
              </div>

              <div></div>

              <ButtonGroup>
                {buttons}
                <ModalButton onClick={closeModal}>
                  <img alt={"XButton"} src="/assets/icons/left-icon.svg" />
                </ModalButton>
              </ButtonGroup>
            </>
          )}
        </Header>

        {/*<Content>*/}
        <Content className={handleOk === undefined && ""}>{children}</Content>

        <Footer className={"footer"}>
          <div>{footer}</div>

          <ButtonGroup>
            <ModalButton onClick={closeModal}>닫기</ModalButton>
          </ButtonGroup>
        </Footer>
      </Padding>
    </ReactModal>
  );
}

const Padding = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 1743px; */
  /* height: 883px; */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px 24px;

  & > div {
    width: 33.3%;

    &:nth-of-type(2) {
      //&:nth-child(2) {
      text-align: center;
    }
  }

  h4 {
    white-space: nowrap;
  }
`;

const Content = styled.div`
  height: 100%;
  padding: 0 24px;

  overflow-y: auto;
  > div {
    width: 1695px;
    height: calc(100% - 16px);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

const SidebarButton = styled.img`
  cursor: pointer;
`;

export default TableModal;
