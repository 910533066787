import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactCalendar from "react-calendar";
import { HOST_URL } from "../bin/env";
import axiosInstance from "../library/axios-index";

function AttendanceCalendar({
  handleAttendanceInfo = (data) => {},
  data = [],
  newDate = new Date(),
  handleAttendanceList = (data) => {},
}) {
  const [value, onChange] = useState(newDate);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(newDate).format("YYYY-MM")
  );

  const loadData = async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/laborEmploycommuteRecordList`, {
      lang: "ko",
      uuid: "string",
      token,
      user_site_seq: data.user_site_seq,
      month_dt: selectedDate,
    });

    setCalendarData(result);
  };

  useEffect(() => {
    (async () => {
      await loadData();
    })();
    handleAttendanceList(selectedDate);
  }, [selectedDate]);

  return (
    <div>
      <StyledCalendar
        onChange={(e) => {
          onChange(e);

          setSelectedDate(dayjs(e).format("YYYY-MM"));

          handleAttendanceInfo(dayjs(e).format("YYYY-MM-DD"));
        }}
        // value={value}
        formatDay={(locale, date) => dayjs(date).format("D")}
        minDetail="month"
        maxDetail="month"
        prev2Label={null}
        next2Label={null}
        prevLabel={
          <img
            src="/assets/icons/chevron-left.svg"
            style={{ paddingInline: "0px" }}
            onClick={() => {
              setSelectedDate(
                dayjs(selectedDate).add(-1, "month").format("YYYY-MM")
              );
            }}
          />
        }
        nextLabel={
          <img
            src="/assets/icons/chevron-right.svg"
            onClick={() => {
              setSelectedDate(
                dayjs(selectedDate).add(1, "month").format("YYYY-MM")
              );
            }}
          />
        }
        showNeighboringMonth={true}
        tileContent={({ date, view }) => {
          const e = calendarData.find(
            (e) => dayjs(e.date).format("MM-DD") === dayjs(date).format("MM-DD")
          );

          return e ? (
            <TileContent>
              <JoinTileContent>
                {e.join ? dayjs(e.join).add(-9, "hour").format("HH:mm") : ""}
              </JoinTileContent>
              <LeaveTileContent>
                {e.leave ? dayjs(e.leave).add(-9, "hour").format("HH:mm") : ""}
              </LeaveTileContent>
            </TileContent>
          ) : (
            <TileContent />
          );
        }}
      />
    </div>
  );
}

const StyledCalendar = styled(ReactCalendar)`
  button {
    background: #fff;
    border: none;
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
  }

  .react-calendar__navigation__label {
    color: #171923;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
  }

  .react-calendar__navigation__prev-button {
    padding-inline: 0px;
  }

  .react-calendar__navigation__next-button {
    padding-inline: 0px;
  }

  .react-calendar__month-view__weekdays {
    margin: 16px 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #718096;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    height: 53px;
    color: #171923;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #fff;
    border: none;
    margin-bottom: 8px;
    padding: 0 4px;

    abbr {
      display: inline-block;
      font-size: 11px;
      width: 16px;
      height: 16px;
      padding: 3px 3px;
      line-height: 16px;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #e2e8f0;
  }

  .react-calendar__tile--active {
    abbr {
      background: #171923;
      color: #fff;
      border-radius: 50%;
    }
  }
`;

const TileContent = styled.div`
  height: 23px;
  font-size: 8px;
  color: #171923;
  margin-top: 5px;
  text-align: left;

  div {
    border-radius: 2px;
    padding-left: 3px;
  }
`;

const JoinTileContent = styled.div`
  background: #e4f6ff;
`;

const LeaveTileContent = styled.div`
  background: #ffe7e7;
  margin-top: 2px;
`;

export default AttendanceCalendar;
