import React from "react";
import styled from "@emotion/styled";
import ButtonComponent from "./Button.js";
import { useNavigate } from "react-router-dom";
const TopBannerComponent = ({ fix = true }) => {
  const navigate = useNavigate();
  return (
    <Component fix={`${fix}`}>
      <div>
        <span>
          <img src="/assets/icons/alert-circle.svg"></img>
          수수료 미결제건이 존재합니다.
        </span>
        <em>
          10일 이상 미결제시 표시되며 30일 이상 미결제시 기능이 제한됩니다.
        </em>
      </div>

      <ButtonComponent
        style={{ lineHeight: "32px" }}
        onClick={(event) => {
          event.preventDefault();
          navigate("/setting/payment-information");
        }}
      >
        결제하기
      </ButtonComponent>
    </Component>
  );
};
const Component = styled.div`
  padding: 16px 25px;
  box-sizing: border-box;
  background-color: #373737;
  color: #fff;
  min-height: 100px;
  position: ${(props) => (props.fix === "true" ? "fixed" : "unset")};
  top: 0;
  left: 250px;
  right: 0;
  width: ${(props) => (props.fix === "true" ? "calc(100vw - 250px)" : "unset")};
  z-index: 9999;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    column-gap: 5px;
    img {
      width: 18px;
      height: 18px;
    }
  }

  em {
    font-size: 14px;
    font-weight: 400;
    color: #a3a3a3;
    margin-left: 25px;
    margin-top: 10px;
    display: block;
  }
`;
export default TopBannerComponent;
