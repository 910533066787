import { useEffect, useState } from "react";
import {
  Link as DomLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Menu,
  MenuItem as ReactMenuItem,
  Sidebar as ReactSidebar,
} from "react-pro-sidebar";

import styled from "@emotion/styled";
import axiosInstance from "./library/axios-index";
import AxiosNavigate from "./library/axios-navigate";
import { useCookies } from "react-cookie";
import { checkRole } from "./bin/common";
import { CompanyPlan, RoleType } from "./bin/enum";
import { companyState } from "./library/recoil/atoms/companyState";
import { useRecoilState, useResetRecoilState } from "recoil";
import { verifyUserState } from "./library/recoil/atoms/verifyUserState";
import { getPollingData } from "./library/axios-query";
import { useMutation } from "@tanstack/react-query";
import { planState } from "./library/recoil/atoms/planState";
import { versionState } from "./library/recoil/atoms/versionState.js";
import TopBannerComponent from "./compontents/TopBannerComponent.js";

const logo = "/assets/images/ilsa-logo.png";
const avatar = `/assets/images/profile.png`;

const navs = [
  {
    icon: "/assets/icons/layout-alt-01.svg",
    label: "대시보드",
    url: "/",
  },
  {
    icon: "/assets/icons/tool-01.svg",
    label: "공사일보",
    url: "/report",
  },
  {
    icon: "/assets/icons/clipboard.svg",
    label: "현장 관리",
    url: "/site",
  },
  {
    icon: "/assets/icons/shield-tick.svg",
    label: "관리자 설정",
    url: "/admin",
  },
  {
    isHeader: true,
    label: "노무관리",
  },
  {
    icon: "/assets/icons/users-01.svg",
    label: "근로자 관리",
    url: "/labor/employ",
  },
  {
    icon: "/assets/icons/file-05.svg",
    label: "출역 일보",
    url: "/labor/attendance",
  },
  {
    icon: "/assets/icons/box.svg",
    label: "출역 현황",
    url: "/labor/attendance-status",
  },

  {
    icon: "/assets/icons/file-attachment-02.svg",
    label: "노무비 지급명세서",
    url: "/labor/payment-statement",
  },
  {
    icon: "/assets/icons/file-attachment-02.svg",
    label: "월별노무현황",
    url: "/labor/monthly-payment-statement",
  },
  {
    icon: "/assets/icons/edit-05.svg",
    label: "지급명세서 신고",
    url: "/labor/statement-submission",
  },
  {
    icon: "/assets/icons/receipt-check.svg",
    label: "급여 이체요청서",
    url: "/labor/transfer-request",
  },
  {
    icon: "/assets/icons/edit-05.svg",
    label: "4대보험 업무",
    url: "/labor/insurance-detail",
  },
  {
    isSubNav: true,
    icon: "/assets/icons/edit-05.svg",
    label: "상세내역",
    url: "/labor/insurance-detail",
  },
  {
    isSubNav: true,
    icon: "/assets/icons/edit-05.svg",
    label: "신고자료",
    url: "/labor/download-report",
  },
  {
    isHeader: true,
    label: "지출관리",
  },
  {
    icon: "/assets/icons/coins-stacked-01.svg",
    label: "전도금 지출내역",
    url: "/expense/status",
  },
  {
    icon: "/assets/icons/credit-card-edit.svg",
    label: "전도금 신청내역",
    url: "/expense/request",
    badge: "imprestCount",
  },
  {
    icon: "/assets/icons/payment-information.svg",
    label: "결제정보",
    url: "/setting/payment-information",
    badge: "paymentCount",
  },
  {
    isHeader: true,
    label: "외주 관리",
  },
  {
    icon: "/assets/icons/users-01.svg",
    label: "외주 관리",
    url: "/outsourcing/management",
  },
  {
    isHeader: true,
    label: "설정",
  },
  {
    icon: "/assets/icons/building-05.svg",
    label: "회사정보",
    url: "/setting/company",
  },
  {
    icon: "/assets/icons/user-square.svg",
    label: "직원 관리",
    url: "/setting/employ",
  },
  {
    icon: "/assets/icons/shield-tick.svg",
    label: "권한 설정",
    url: "/setting/role",
  },
  {
    icon: "/assets/icons/announcement-02.svg",
    label: "공지사항",
    url: "/setting/notice",
  },

  {
    isHeader: true,
    label: "매뉴얼",
  },
  {
    icon: "/assets/icons/file-05.svg",
    label: "매뉴얼",
    url: "https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/ilsa/manual/ilsa_setup_manual.pdf",
  },
];
// 30일 이상 미결제건 존재시
const nonPaymentNavs = [
  {
    icon: "/assets/icons/layout-alt-01.svg",
    label: "대시보드",
    url: "/",
  },
  {
    icon: "/assets/icons/tool-01.svg",
    label: "공사일보",
    url: "/report",
  },
  {
    icon: "/assets/icons/clipboard.svg",
    label: "현장 관리",
    url: "/site",
  },
  {
    icon: "/assets/icons/payment-information.svg",
    label: "결제정보",
    url: "/setting/payment-information",
    badge: "paymentCount",
  },
  {
    icon: "/assets/icons/file-05.svg",
    label: "매뉴얼",
    url: "https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/ilsa/manual/ilsa_setup_manual.pdf",
  },
];

const adminNavs = [
  {
    isHeader: true,
    label: "관리자",
  },
  {
    icon: "/assets/icons/building-05.svg",
    label: "회사 관리",
    url: "/admin/company",
  },
  {
    icon: "/assets/icons/clipboard.svg",
    label: "현장 관리",
    url: "/admin/site",
  },
  {
    icon: "/assets/icons/users-01.svg",
    label: "사용자 관리",
    url: "/admin/user",
  },
  {
    icon: "/assets/icons/payment-information.svg",
    label: "할인",
    url: "/admin/discount-rule",
  },
  {
    isSubNav: true,
    icon: "/assets/icons/edit-05.svg",
    label: "할인 정책",
    url: "/admin/discount-rule",
  },
  {
    isSubNav: true,
    icon: "/assets/icons/edit-05.svg",
    label: "할인 적용",
    url: "/admin/discount-apply",
  },
  {
    icon: "/assets/icons/payment-information.svg",
    label: "결제정보",
    url: "/admin/payment-information",
  },
  {
    icon: "/assets/icons/announcement-02.svg",
    label: "팝업 관리",
    url: "/admin/notice",
  },
  {
    icon: "/assets/icons/announcement-02.svg",
    label: "도움말 관리",
    url: "/admin/board",
  },
  {
    icon: "/assets/icons/shield-tick.svg",
    label: "관리자 설정",
    url: "/admin",
  },
  {
    isHeader: true,
    label: "파트너스",
  },
  {
    icon: "/assets/icons/users-01.svg",
    label: "파트너 관리",
    url: "/partners/admin",
  },
  {
    icon: "/assets/icons/building-05.svg",
    label: "고객사별 파트너",
    url: "/partners/admin/customer",
  },
  {
    icon: "/assets/icons/payment-information.svg",
    label: "정산",
    url: "/partners/admin/payment-information",
  },
  {
    icon: "/assets/icons/announcement-02.svg",
    label: "공지사항 관리",
    url: "/partners/admin/board",
  },
];

function AppLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  const [toggled, setToggled] = useState(false);
  const [info, setInfo] = useRecoilState(verifyUserState);
  const [webVersion, setWebVersion] = useRecoilState(versionState);
  const resetCompanyState = useResetRecoilState(companyState);
  const resetInfo = useResetRecoilState(verifyUserState);
  const resetPlan = useResetRecoilState(planState);
  const [pollingData, setPollingData] = useState({
    imprestCount: 0,
    paymentCount: 0,
    unpaidDay: 0,
  });
  const [defaultUrl, setDefaultUrl] = useState("/");
  const [cookies, setCookie] = useCookies(["role"]);

  // polling-data api - react-query 버전
  const { mutate: getPollingMutate, data: getPolling } = useMutation({
    mutationKey: "getPollingData",
    mutationFn: getPollingData,
    onSuccess: (data) => {
      if (data.webVersion !== webVersion?.webVersion) {
        setWebVersion({ webVersion: data.webVersion });
        window.location.reload();
      }

      setPollingData({
        imprestCount: data?.imprestCount, // 전도금 신청내역 개수
        paymentCount: data?.paymentCount, // 미결제내역 개수
        unpaidDay: data?.unpaidDay, // 10일 이상 미결제건 체크(일수)
      });
    },
    onError: (error) => {
      throw error;
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    if (!token && location.pathname !== "/login") {
      navigate("/login");
      resetCompanyState();
      resetInfo();
      resetPlan();
    } else {
      (async () => {
        try {
          // const {data: {result}} = await axiosInstance.post(`/company/verifyUser`, {
          //     lang: 'ko',
          //     uuid: 'string',
          //     token
          // });
          // 해당 유저의 접근 권한을 가져와서 쿠키에 저장
          const userRole = await axiosInstance.post(`/admin/userRole`);
          if (info.corp_id === "admin") {
            setDefaultUrl("/admin/company");
          }
          setCookie("role", userRole.data.result);
        } catch (error) {
          //console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    if (!token && location.pathname !== "/login") {
      navigate("/login");
    } else {
      if (cookies?.role?.admin != undefined) {
        if (location.pathname !== "/") {
          if (!checkRole(location.pathname, cookies.role, RoleType.READ)) {
            navigate(-1);
          }
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    getPollingMutate();
  }, [location.pathname]);

  return (
    <AxiosNavigate container={false}>
      <div>
        <Wrapper>
          <Header>
            <HeaderContent></HeaderContent>
            <HeaderContent style={{ justifyContent: "center" }}>
              {
                <h3>
                  {info?.admin
                    ? adminNavs.find((e) => e.url === location.pathname)?.label
                    : navs.find((e) => e.url === location.pathname)?.label}
                </h3>
              }
            </HeaderContent>
            <HeaderContent
              onClick={() => setToggled(!toggled)}
              style={{ justifyContent: "flex-end" }}
            >
              <SidebarButton alt="bars" src="/assets/icons/menu-01.svg" />
            </HeaderContent>
          </Header>
          <Container>
            <Sidebar
              toggled={toggled}
              onBackdropClick={() => setToggled(false)}
              breakPoint="lg"
              backgroundColor="#F7FAFC"
            >
              <Menu
                menuItemStyles={{
                  button: ({ level, active, disabled }) => ({}),
                }}
              >
                <Link to={defaultUrl} onClick={() => setToggled(false)}>
                  <Company>
                    <img src={logo} alt={"logo"} />
                    <CompanyName>{info.corp_nm}</CompanyName>
                  </Company>
                </Link>
                {info.admin
                  ? adminNavs.map((e, i) =>
                      e.isHeader ? (
                        <MenuHeader key={i}>{e.label}</MenuHeader>
                      ) : e.isSubNav ? (
                        e.label === "할인 정책" || e.label === "할인 적용" ? (
                          <MenuItem
                            key={i}
                            selectedmenu={
                              e.url === location.pathname ? "true" : "false"
                            }
                            component={
                              <LinkItem
                                className={"height"}
                                style={{
                                  height: "40px",
                                  paddingLeft: "55px",
                                }}
                                onClick={(event) => {
                                  setToggled(false);
                                  navigate(e.url);
                                }}
                              />
                            }
                          >
                            {e.label}
                          </MenuItem>
                        ) : (
                          <div key={i}></div>
                        )
                      ) : (
                        <MenuItem
                          key={i}
                          selectedmenu={
                            e.url === "/admin/discount-rule"
                              ? "false"
                              : e.url === location.pathname
                              ? "true"
                              : "false"
                          }
                          suffix={null}
                          component={
                            <LinkItem
                              onClick={(event) => {
                                setToggled(false);
                                navigate(e.url);
                              }}
                            />
                          }
                          icon={<MenuIcon src={e.icon} />}
                        >
                          {e.label}
                        </MenuItem>
                      )
                    )
                  : pollingData.unpaidDay < 30
                  ? navs.map((e, i) =>
                      e.isHeader ? (
                        <MenuHeader key={i}>{e.label}</MenuHeader>
                      ) : e.url === "/admin" && !info.admin ? (
                        <div key={i}></div>
                      ) : e.isSubNav ? (
                        e.label === "상세내역" || e.label === "신고자료" ? (
                          // e.isSubNav ? (e.label === '상세내역') || (e.label === '신고자료' && (info?.plan !== CompanyPlan.BASIC)) ? (
                          <MenuItem
                            key={i}
                            selectedmenu={
                              e.url === location.pathname ? "true" : "false"
                            }
                            component={
                              <LinkItem
                                className={"height"}
                                style={{
                                  height: "40px",
                                  paddingLeft: "55px",
                                }}
                                onClick={(event) => {
                                  setToggled(false);
                                  navigate(e.url);
                                }}
                              />
                            }
                          >
                            {e.label}
                          </MenuItem>
                        ) : (
                          <div key={i}></div>
                        )
                      ) : e.url === "/setting/company" ? (
                        <MenuItem
                          key={i}
                          selectedmenu={
                            e.url === location.pathname ? "true" : "false"
                          }
                          suffix={
                            <Badge
                              style={{
                                width: "65px",
                                height: "19px",
                                alignContent: "center",
                                textAlign: "center",
                                marginRight: "10px",
                                background: "#3279F5",
                                color: "#fff",
                              }}
                            >
                              {info?.planName}
                            </Badge>
                          }
                          component={
                            <LinkItem
                              onClick={(event) => {
                                setToggled(false);
                                navigate(e.url);
                              }}
                            />
                          }
                          icon={<MenuIcon src={e.icon} />}
                        >
                          {e.label}
                        </MenuItem>
                      ) : (e.url == "/setting/employ" ||
                          e.url == "/setting/role") &&
                        info?.plan == CompanyPlan.BASIC ? (
                        <div key={i}></div>
                      ) : (
                        <MenuItem
                          key={i}
                          selectedmenu={
                            e.url === "/labor/insurance-detail"
                              ? "false"
                              : e.url === location.pathname
                              ? "true"
                              : "false"
                          }
                          component={
                            <LinkItem
                              onClick={(event) => {
                                if (e.label !== "매뉴얼") {
                                  setToggled(false);
                                  navigate(e.url);
                                } else {
                                  // nav 매뉴얼 pdf 오픈
                                  window.open(e.url);
                                }
                              }}
                            />
                          }
                          suffix={
                            e.badge ? (
                              <Badge
                                className={`${
                                  pollingData[e.badge] > 0 && "action"
                                } `}
                              >
                                {pollingData[e.badge]}
                              </Badge>
                            ) : null
                          }
                          icon={<MenuIcon src={e.icon} />}
                        >
                          {e.label}
                        </MenuItem>
                      )
                    )
                  : nonPaymentNavs.map((e, i) => (
                      <MenuItem
                        key={i}
                        component={
                          <LinkItem
                            onClick={(event) => {
                              if (e.label !== "매뉴얼") {
                                setToggled(false);
                                navigate(e.url);
                              } else {
                                // nav 매뉴얼 pdf 오픈
                                window.open(e.url);
                              }
                            }}
                          />
                        }
                        suffix={
                          e.badge ? (
                            <Badge
                              className={`${
                                pollingData[e.badge] > 0 && "action"
                              } `}
                            >
                              {pollingData[e.badge]}
                            </Badge>
                          ) : null
                        }
                        icon={<MenuIcon src={e.icon} />}
                      >
                        {e.label}
                      </MenuItem>
                    ))}

                <Profile>
                  {info.photo == "" || info.photo == null ? (
                    <Avatar
                      style={{
                        backgroundImage: `url(${avatar || "/avatar.png"})`,
                      }}
                    />
                  ) : (
                    <Avatar style={{ backgroundImage: `url(${info.photo})` }} />
                  )}
                  <Info>
                    <Name>{info.manager_nm}</Name>
                    <Email>{info.corp_id}</Email>
                  </Info>
                  <Link
                    style={{ marginLeft: "auto" }}
                    to="/login"
                    onClick={() => {
                      localStorage.setItem("admin_token", "");
                      setToggled(false);
                      // 회사정보 recoil 초기화
                      resetCompanyState();
                      resetInfo();
                      resetPlan();
                    }}
                  >
                    <Logout src="/assets/icons/log-out-02.svg" />
                  </Link>
                </Profile>
              </Menu>
            </Sidebar>
            <Content>
              {!info?.admin &&
                pollingData.unpaidDay >= 10 &&
                location.pathname !== "/setting/payment-information" && (
                  <TopBannerComponent fix={false} />
                )}
              <Outlet />
            </Content>
          </Container>
        </Wrapper>
      </div>
    </AxiosNavigate>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  width: 33.3%;

  h3 {
    overflow: hidden;
    color: #171923;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  width: 50%;
  /* padding-top: 100px; */
`;

const Footer = styled.div`
  padding: 10px;
  text-align: center;
`;

const Link = styled(DomLink)`
  color: #000;
  text-decoration: none;
  display: block;
  cursor: pointer;
`;

const Sidebar = styled(ReactSidebar)``;

const SidebarButton = styled.img`
  width: 32px;
  cursor: pointer;
`;

const MenuHeader = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  color: #718096;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

const MenuItem = styled(ReactMenuItem)`
  color: #4a5568;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  background-color: ${({ selectedmenu }) =>
    selectedmenu === "true" && "#E2E8F0"} !important;

  > div {
    height: 35px !important;
  }

  .height {
    height: 25px !important;
  }

  span {
    margin: 0;
    width: fit-content;
    min-width: fit-content;
    margin-right: 10px;
  }
`;

const MenuIcon = styled.img``;

const Profile = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
`;

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
`;

const Info = styled.div`
  padding-left: 12px;
  padding-right: 6px;
`;

const Name = styled.div`
  color: #1a202c;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
`;
const Email = styled.div`
  color: #718096;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
`;

const Logout = styled.img``;

const Badge = styled.div`
  padding: 1px 6px;
  color: #4a5568;
  background: #e2e8f0;
  //background: #E2E8F0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
  border-radius: 6px;

  &.action {
    background: #ff3838;
    color: #fff;
  }
`;

const Company = styled.div`
  display: flex;
  align-items: center;
  padding: 21px 16px;
  cursor: pointer;
  // flex-wrap: wrap;

  img {
    width: 35%;
    margin-right: 3px;
  }
`;

const LinkItem = styled.div`
  height: 35px;
`;

const CompanyName = styled.div`
  color: #030229;
  font-size: 17.317px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 2px;
`;

export default AppLayout;
