import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios, { HttpStatusCode } from "axios";
import axiosInstance from "./axios-index";
import { HOST_URL } from "../bin/env";
import { REACT_APP_API_KEY } from "../bin/env";
import {AxiosRequestConfig} from "axios";
let isRefreshing = false;

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];
export default function AxiosNavigate({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originRequest = error.config;
        if (
          error.response &&
          error.response.status === HttpStatusCode.Unauthorized
        ) {
          if (!isRefreshing) {
            isRefreshing = true;

            try {
              const {
                data: { result },
              } = await axios.post(
                `${HOST_URL}/auth/refresh`,
                {},
                {
                  withCredentials: true,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                      "admin_token"
                    )}`,
                    "X-API-KEY": REACT_APP_API_KEY,
                  },
                }
              );

              localStorage.setItem("admin_token", result.token);
              originRequest.headers["Authorization"] = `Bearer ${result.token}`;

              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              // Clear the queue
              refreshAndRetryQueue.length = 0;
              return axiosInstance.request(originRequest);
            } catch (err) {
              console.log("refresh error : ", err);
              //refresh token 도 만료 시간이 지났을 때 다시 로그인 시키기 위함.

              navigate("/login");
              return Promise.reject(err);
            } finally {
              isRefreshing = false;
            }
          }

          // return () => {
          //     new Promise((resolve, reject) => {
          //         queue.push({ config: originRequest, resolve, reject });
          //     });
          //     axiosInstance.interceptors.response.eject(interceptor);
          // };
          return new Promise((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originRequest,
              resolve,
              reject,
            });
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return <>{children}</>;
}
