export const HOST_URL = process.env.REACT_APP_API_HOST;
export const REACT_APP_KAKAO_API_KEY = process.env.REACT_APP_KAKAO_API_KEY;
export const REACT_APP_KAKAO_MAP_API_KEY =
  process.env.REACT_APP_KAKAO_MAP_API_KEY;
export const REACT_APP_BOOTPAY_APPLICATION_ID =
  process.env.REACT_APP_BOOTPAY_APPLICATION_ID;
export const REACT_APP_NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
export const REACT_APP_NAVER_MAP_API_KEY =
  process.env.REACT_APP_NAVER_MAP_API_KEY;
export const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;
export const REACT_APP_ADDRESS_API_KEY = process.env.REACT_APP_ADDRESS_API_KEY;
