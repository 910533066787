import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

function CustomTimePicker({
  ampm,
  value,
  time,
  disabled,
  onChange,
  tiemData = null,
  overTimeData = null,
  workNightTime = false,
}) {
  const t =
    time !== null
      ? time.split(":")
      : ampm === "am"
      ? ["00", "00"]
      : ["12", "00"];
  const [hour, setHour] = useState(t[0]);
  const [minute, setMinute] = useState(t[1]);

  const hours = [
    ...Array(
      value.includes("_end")
        ? workNightTime
          ? 7
          : ampm === "pm"
          ? 13
          : 19
        : 12
    ).keys(),
  ]
    .map((n) => n + (ampm === "pm" ? (workNightTime ? 0 : 12) : 0))
    .map(String)
    .map((n) => n.padStart(2, "0"));
  const minutes = [
    ...Array(
      value.includes("_end") &&
        ((ampm === "am" && hour == 18) || (ampm == "pm" && hour == 24))
        ? 1
        : 6
    ).keys(),
  ]
    .map(String)
    .map((n) => (n * 10).toString().padStart(2, "0"));

  useEffect(() => {
    onChange(
      value,
      `${hour}:${
        value.includes("_end") &&
        ((ampm === "am" && hour == 18) || (ampm == "pm" && hour == 24))
          ? "00"
          : minute
      }:00`
    );
  }, [hour, minute]);

  const validateTime = (hour, minute) => {
    if (!tiemData) return true;
    const label = value.includes("satday")
      ? "토요일"
      : value.includes("weekend")
      ? "일요일/공휴일"
      : "평일";
    const timeValue = `${hour}:${minute}:00`;

    if (
      (tiemData.label === "am_start" && timeValue > tiemData.am_end) ||
      (tiemData.label === "am_end" && timeValue < tiemData.am_start)
    ) {
      alert(`${label} 오전 시작 시간은 종료 시간보다 작아야 합니다.`);
      return false;
    }

    if (
      (tiemData.label === "am_end" && timeValue > tiemData.pm_start) ||
      (tiemData.label === "pm_start" && timeValue < tiemData.am_end)
    ) {
      alert(`${label} 오후 시작 시간은 오전 종료 시간보다 커야 합니다.`);
      return false;
    }

    if (
      (tiemData.label === "pm_start" && timeValue > tiemData.pm_end) ||
      (tiemData.label === "pm_end" && timeValue < tiemData.pm_start)
    ) {
      alert(`${label} 오후 시작 시간은 종료 시간보다 작아야 합니다.`);
      return false;
    }

    return true;
  };

  const validateOverTime = (hour, minute) => {
    if (!overTimeData) return true;
    if (overTimeData.overtime_start === "") return true;

    const timeValue = `${hour}:${minute}:00`;

    if (
      (tiemData.label === "pm_end" &&
        timeValue > overTimeData.overtime_start) ||
      (tiemData.label === "satday_end" &&
        timeValue > overTimeData.overtime_start) ||
      (tiemData.label === "weekend_end" &&
        timeValue > overTimeData.overtime_start)
    ) {
      alert("야근 시작 시간은 오후 종료 시간보다 커야 합니다.");
      return false;
    }
    if (
      tiemData.label === "overtime_start" &&
      (timeValue < overTimeData.pm_end ||
        timeValue < overTimeData.satday_end ||
        timeValue < overTimeData.weekend_end)
    ) {
      alert("야근 시작 시간은 오후 종료 시간보다 커야 합니다.");
      return false;
    }

    return true;
  };
  return (
    <div style={{ width: "100%" }}>
      <InputBox
        value={hour}
        disabled={disabled}
        onChange={(e) => {
          const validate = validateTime(e.target.value, minute);
          const overTimeValidate = validateOverTime(e.target.value, minute);
          if (validate && overTimeValidate) setHour(e.target.value);
        }}
      >
        {hours.map((hour, index) => (
          <option key={index} value={hour}>
            {hour}
          </option>
        ))}
      </InputBox>
      <span> : </span>
      <InputBox
        value={minute}
        disabled={disabled}
        onChange={(e) => {
          const validate = validateTime(hour, e.target.value);
          const overTimeValidate = validateOverTime(hour, e.target.value);
          if (validate && overTimeValidate) setMinute(e.target.value);
        }}
      >
        {minutes.map((minute, index) => (
          <option key={index} value={minute}>
            {minute}
          </option>
        ))}
      </InputBox>
    </div>
  );
}

const InputBox = styled.select`
  width: 60px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  outline: none;

  padding: 6px 6px;
  margin: 4px;

  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export default CustomTimePicker;
