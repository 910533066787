import React, { forwardRef } from "react";
import ReactSelect, { components } from "react-select";
import styled from "@emotion/styled";

const selectStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    marginTop: "0px",
    // height: '34px',
    // width: '150px',
    //fontSize: '14px',
    minHeight: "34px", // 원하는 높이로 변경
    // height: '34px',
    height: "100%",
    "&:hover": {
      border: "1px solid #E2E8F0",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "100%", // 원하는 높이로 변경
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
    backgroundColor: "transparent",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "100%", // 원하는 높이로 변경
  }),
  placeholder: (provided) => ({
    ...provided,
    " > div": {
      display: "flex",
      alignItems: "center",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    height: "100%",
    // lineHeight: "34px",
    // padding: "0 6px",
    lineHeight: "1.4",
    padding: "7px 6px",
    boxSizing: "border-box",
  }),
};

const Select = styled(ReactSelect)`
  color: ${(props) => props.color || "#171923"} !important;
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-size: ${(props) => props.font || "14px"};
  //font-size: 16px;
  font-style: normal;
  box-sizing: border-box;
  font-weight: 500;
  background: #fff;
  width: ${(props) => props.width || "150px"};
  height: ${(props) => props.height || "34px"};

  //margin-top: 5px;
  border-color: #cbd5e0;

  .react-select__input {
    font-size: ${(props) => props.font || "14px"};
  }

  .react-select__control {
    height: 38px !important;
  }

  > div {
    cursor: pointer;
    color: ${(props) => props.color || "#171923"} !important;
    border: ${(props) => props.border || "1px solid #CBD5E0"};
    //border: 1px solid #CBD5E0;#E2E8F0
  }

  .react-select-placeholder {
    //content: none;
  }
`;
const SingleValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        {/*<Icon src={`/assets/icons/${props.selectProps.iconName}`} style={{marginRight: '8px'}}/>*/}
        {children}
      </SingleValueContainer>
    </components.SingleValue>
  );
};

const customComponents = {
  Input: (props) => <components.Input {...props} readOnly />, // readOnly 속성 추가,
  SingleValue,
};

const SelectedFilterBox = forwardRef((props, ref) => {
  const { placeholder, options, style, onChange, value, disabled } = props;

  return (
    <>
      <Select
        ref={ref}
        styles={selectStyles}
        components={customComponents}
        placeholder={placeholder}
        options={options}
        width={style?.width ? style?.width : "150px"}
        height={style?.height ? style?.height : "34px"}
        color={style?.color}
        font={style?.font}
        border={style?.border}
        onChange={onChange}
        isDisabled={disabled}
        value={value || null}
        // value={{value: value, label: value}}
      ></Select>
    </>
  );
});

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export default SelectedFilterBox;
