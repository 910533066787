import React from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import styled from "@emotion/styled";
import axios from "axios";
import { REACT_APP_KAKAO_API_KEY } from "../bin/env";
const getLongitudeAndLatitude = async (addr) => {
  const result = await axios.get(
    `https://dapi.kakao.com/v2/local/search/address.json?query=${addr}`,
    {
      headers: {
        Authorization: `KakaoAK ${REACT_APP_KAKAO_API_KEY}`,
      },
    }
  );
  const {
    data: { documents },
  } = result;

  return [documents[0].x, documents[0].y];
};
const SearchAddress = (props) => {
  const scriptUrl =
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(scriptUrl);

  const handleComplete = async (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    // console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    props.setAddrAndZoneCode(fullAddress, data.zonecode);

    //위도, 경도가 필요할 경우에만 props로 전달받음.
    if (props.setLongitudeAndLatitude) {
      const [longitude, latitude] = await getLongitudeAndLatitude(fullAddress);
      props.setLongitudeAndLatitude(longitude, latitude);
    }
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <FormButton type="button" onClick={handleClick}>
      주소찾기
    </FormButton>
  );
};

export const FormButton = styled.div`
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 8px;
  background-color: #708090;
  color: aliceblue;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  width: fit-content;
  margin-top: 12px;
  margin-bottom: 15px;
`;

export default SearchAddress;
